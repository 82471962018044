import CodeBox from "../../../components/extra/CodeBox";
import ArticleSection from "../../../components/layout/ArticleSection";
import CodeExample from "../../../components/extra/CodeExample";
import InfoBox from "../../../components/extra/InfoBox";

export default function SerializationDoc() {
    const payloadSerialization = {exampleDate: {"<dateTime>": "2022-09-25T15:12:07.854970"}}
    const schemaSerialization = {
        "exampleDate":{
            "type":"object",
            "properties":{
                "<dateTime>":{
                    "type":"string",
                    "format": "date-time"
                }
            }
        }
    }
    return (
        <div className="Article apiDoc">
            <div className="articleCentered">
                <h3 className="articleTitle">Query and Serialization</h3>
                <p className="articleText">
                Sometimes you may want to store complex data types in your records that go beyond native data types. A common example is time stamps. While you can of course handle your own serialization/deserialization on your end with your own conventions, <span className="textBold">you may want to leverage complex data types such as time stamps in your query logic</span> (for example query all records with a time stamp after a specific date).
                </p>
                <p className="articleText">
                <span className="textBold">To save the data as a complex type,</span> you must denote the data is serialized with special syntax. Below are the current supported conventions for payload and URL parameter serializations.
                </p>
                <h3 className="articleSubTitle flexTitle" id="payloadser"> <span className="material-symbols-outlined icon">subtitles</span>Payload/JSON Serialization</h3>
                <p className="articleText">If you are serializing data in the <span className="textBold">body</span> of the payload, use the following methods.</p>
                <ArticleSection rows={[
                    <>  
                        <p className="articleText"><span className="textBold">Date time:</span> Send your datetime as an iso string and nest it in an object with a single key called <code className="inlineCode textBold">{'<dateTime>'}</code> as seen in the following payload example.</p>
                        <CodeBox lines={true} codeTitle='Payload serialization ' codeText={JSON.stringify(payloadSerialization,null,4)} headerView={false}/>
                        <p className="articleText">When formatting schema for the route which hosts datetime aware records, format the datetime attribute schema as shown in the schema example.</p>
                        <CodeBox lines={true} codeTitle='Schema serialization' codeText={JSON.stringify(schemaSerialization,null,4)} headerView={false}/>
                        <InfoBox color='Yellow' text={(
                            <span><span className="textBold">Note:</span> All key values in your records must follow the same rules/limitations of the reserved docId attribute... That is, they can only contain letters, numbers, underscores, and dashes such as <code className="textBold">(A-Z,a-z,0-9,_,-)</code> with a length between 3 and 40 characters (inclusive)</span>
                        )}/>
                    </>
                ]} />
                <h3 className="articleSubTitle flexTitle" id="urlser"><span className="material-symbols-outlined icon">search</span>URL Query Serialization</h3>
                <p className="articleText">If you are serializing query values in the URL, use the following methods.</p>
                <p className="articleText"><span className="textBold">Operations:</span> When executing query in the URL, the following operations are supported: <code className="inlineCode textBold">{'=, !=, >=, <='}</code>. These operations represent <span className="textBold">equal to</span>, <span className="textBold">not equal to</span>, <span className="textBold">greater or equal to</span>, and <span className="textBold">lesser or equal to</span> respectively.</p>
                <p className="articleText"><span className="textBold">Values:</span> If you wrap the query value in double quotes, the value will be interpreted as a string. If the query value is not wrapped in double quotes ("") the api will attempt to cast the value as a <code className="inlineCode textBold">bool, number, iso, or null</code>:
                </p>
                <ol className="serializationNotes articleText">
                    <li>
                        <span><span className="textBold">"False"</span> and <span className="textBold">"false"</span> are strings but <span className="textBold">False</span> and <span className="textBold">false</span> are boolean values</span>,
                    </li>
                    <li>
                        <span><span className="textBold">"2022-11-15T00:24:14.000Z"</span> is a string but <span className="textBold">2022-11-15T00:24:14.000Z</span> is a datetime</span>
                    </li>
                </ol>
                <p className="articleText textBold">Examples:</p>
                <CodeExample title={'Examples'} lines={[
                    'GET https://app.postget.dev/owner/api/v1/apps?limit>=500',
                    'GET https://app.postget.dev/api/v1/client/widgets?inStock=True&size="Large"'
                ]}/>
                <p className="articleText"><span className="textBold">Note:</span> Please note the following expected behaviors</p>
                <ol className="serializationNotes articleText">
                    <li>Iso type will be converted to datetime object</li>
                    <li>Values without quotes that cannot be cast will default to strings</li>
                    <li>All deserialization occurs prior to query execution</li>
                    <li>To query against a nested key in your data, use the following notation: <code className="inlineCode textBold">?rootKey.subKey=value</code></li>
                    <li><span className="textBold">postget defined query params such as 'limit' will always be interpreted as the expected type regardless of convention</span></li>
                </ol>
            </div>
      </div>
    )
}