import './ApiRoute.css'
import CodeExample from '../extra/CodeExample'
import ParamBox from './ParamBox'
import MethodList from './MethodList'
import { nanoid } from 'nanoid'

export default function ApiRoute({route, last}) {
  const {
    title,
    description,
    baseUrl,
    params,
    methods
  } = route
  return (
    <div className={last ? 'routeHeaderInfo lastRoute' : 'routeHeaderInfo'}>
            
      <div 
        className='routeHeaderHead'
        id ={ title.toLowerCase().includes('index') 
                  ? 'index' 
                  : title.toLowerCase().includes('detail') 
                  ? 'detail' 
                  : ''}>
        <h3 className='routeHeaderTitle'><span className="material-symbols-outlined">bolt</span>{title.toUpperCase()}</h3>
      </div>

      <div className='routeHeaderBody'>
        <p className='routeHeaderDesc articleText'><em>{`"${description}"`}</em></p>
        <div className='routeHeaderExample'>
          <div className='routeUrl'>
            <CodeExample lines={[<span>curl {baseUrl}</span>]}/>
          </div>
          {params && params.map(param => <span key={nanoid()}><ParamBox params={param.variables} type={param.type}/></span>)}
        </div>

        <MethodList methods={methods}/>

      </div>
    </div>
  )
}