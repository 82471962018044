import './ResponseCodes.css'
import { nanoid } from 'nanoid'

export default function ResponseCodes({responses}) {

  return (
    <div className='responseCodes articleText'>
        <div className='responseCodesHeader'>
            <h4>Response codes</h4>
        </div>
        <div className='responseCodesBody'>
            <ul className='routeResponses'>
                {responses.map((response)=>{
                    return <li className='routeResponse' key={nanoid()}>
                        <div className={`statusCode ${response.color}Text`}>{response.code}</div>
                        <div className='statusExpl'>{`: ${response.desc}`}</div>
                    </li>
                })}
            </ul>
        </div>

    </div>
  )
}