import './ApiKeys.css'
import { useSearchParams } from 'react-router-dom';
import ActivityFeed from '../../components/extra/ActivityFeed';
import Modal from '../../components/generic/Modal';
import Button from '../../components/generic/Button';
import { useKey } from '../../hooks/useKey'
import { useState } from 'react';
import { useDocument } from '../../hooks/useDocument';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useCounter } from '../../hooks/useCounter';
import CopyButton from '../../components/extra/copyButton';
// side routes: keys, usage

export default function ApiKeys() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [showKey, setShowKey] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const {user} = useAuthContext()
    const { document: userDoc } = useDocument('users',user.uid)
    const {total:dailyUsage} = useCounter(`users/${user.uid}/apiCounter`)

    const view = searchParams.get('view')
    const { key, feedDoc, resetKey } = useKey()

    return (
      <>
        {showModal && <Modal 
                      setShowModal={setShowModal} 
                      onConfirm={()=>{resetKey()}}
                      header='Recycle API key'
                      message={
                        <div className='padModal'>Delete your old key and generate a new one? <span className="textBold">This action is NOT reversible and may take several seconds to propagate</span></div>}
                      />}
        <div className="apiKeys Article ArticleNoNav">
          <div className="articleCentered">
            <h3 className='articleTitle'>{userDoc?.disabled ? <span className='dangerText'>ACCOUNT DISABLED</span> : "API keys"}</h3>
            {view === 'history' && (
            <>
              <ActivityFeed feed={feedDoc || []}/>
            </>
            )}
            {view === 'secret' && (
              <>
                <p className='articleText'>
                Access and manage your private API key below. Your key grants you owner level access to your apps and all related sub collections and documents. <span className='textBold'>DO NOT SHARE YOUR SECRET KEY WITH ANYONE.</span> If you suspect your key has been compromised, use the refresh link below to generate a new one.
                </p>

                <table className='keyTable bigTable'>
                  <thead>
                    <tr>
                      <th>SECRET KEY</th>
                      <th></th>
                      <th className='hideSmall'>CREATED</th>
                      <th className='dailyUsage'>DAILY USAGE</th>
                      <th className='daily'>DAILY</th>
                      <th className='hideSmall'>LIMIT</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='keyColumn'>
                        <div className='secretBlock'>
                        {showKey && key ? key : '***************'}
                        </div>
                      </td>
                      <td className='rowText'>
                        <CopyButton className='apiLink apiLinkCopy' text={key}/>
                        <span 
                        className="material-symbols-outlined visibilityIcon apiLink"
                        onClick={()=>{setShowKey(prev=>!prev)}}
                        >
                            {showKey ? "visibility_off" : "visibility"}
                        </span>
                      </td>
                      <td className='hideSmall'>{userDoc?.signedUpOn?.toDate().toISOString().split('T')[0] || 'dateTime'}</td>
                      <td>{dailyUsage}</td>
                      <td className='hideSmall'>50000</td>
                      <td>
                        <span className="material-symbols-outlined apiLink" onClick={()=>setShowModal(true)}>
                          sync
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className='buttonLinks'>
                  <Button route='/docs?view=start' color='Primary' size='Medium' text={<span className='buttonTextBox'><span className="material-symbols-outlined icon">arrow_forward</span>{' Quick start'}</span>}/>
                </div>
                <hr></hr>
                <ActivityFeed feed={feedDoc || []} reverse={true}/>
              </>
            )}
          </div>
        </div>
      </>
    );
  }