
export default function SimpleButton({text, color, icon, onClick=()=>{}}) {
  return (
    <button className={`btn btn${color}`} onClick={()=>onClick()}>
        <span className="material-symbols-outlined btnIcon">
            {icon}
        </span>
        <span>{text}</span>
    </button>
  )
}