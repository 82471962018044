import { useAuthContext } from './useAuthContext'
import { useDocument } from "./useDocument"

export const useSubscribe = () => {
    const {user} = useAuthContext()
    const { document: userDoc } = useDocument('users',user?.uid)

    const subscribe = async (appPath) => {
        
        const key = userDoc?.apiClient?.apiKey
        if (!key || !appPath) return

        try {
            const res = await fetch(
                // `http://localhost:8080/api/v1/store/subscribe?appPath=${appPath}`,
                `https://app.postget.dev/api/v1/store/subscribe?appPath=${appPath}`,

                {
                    method: 'POST',
                    headers: {
                        Authorization:`Basic ${key}`,
                        Origin: 'https://postget.dev',
                        'Access-Control-Request-Method': 'POST',
                        'Access-Control-Request-Headers': 'Content-Type, Authorization'
                    }
                }
            )

            const json = await res.json()
            // on backend, subscribe writes the client doc with key then
            // updates the user doc alias keys... this should re-trigger
            // useAliases hook to refresh as user doc is a effect dependency
            return json.response === "subscribed successfully"

        } catch {
            return false
        }
    }


    return {subscribe}
}