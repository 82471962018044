import './About.css'
import Button from '../../components/generic/Button';
import Footer from '../../components/layout/Footer';
import LogoIcon from '../../assets/logo_large.png'
import { Link } from 'react-router-dom';
import cowboy from '../../assets/3d-fluency-cowboy-hat-face.png'
import starface from '../../assets/3d-fluency-star-struck.png'
import explodeface from '../../assets/3d-fluency-exploding-head.png'

export default function About() {
    return (
      <div className="aboutPage">
        <div className='aboutBanner'>
          <div className='aboutBannerContainer'>
            {/* <h3 className='articleTitle'>postget</h3> */}
            <div className='logoHolder'>
              <p><img src={LogoIcon} alt='postget logo' aria-label='postget logo' className='logoIcon'></img></p>
              <h3 className='logoText'>postget</h3>
            </div>
            <div className='bannerInfo'>
              <p className='articleText bannerText textBold largeText'>Using an API is easy but building an API is tedious...</p>
              <p className='articleText bannerText'>
                  Let's skip ahead to the good stuff. When you create an account,
                  you'll have access to deploy complex applications with only a few lines of code. <Link to='/docs?view=example' className='appLink textBold'><span className="material-symbols-outlined smallText">lightbulb</span>Check out this demo!</Link>
              </p>
            </div>
              <div className='buttonLinks' id="btnVerbose">
                <Button size='Medium' text={
                  <span className='buttonTextBox'>
                    <span className="material-symbols-outlined icon">arrow_forward</span>
                    How it works
                  </span>}  color='How' route='/docs?view=start'/>
                <Button size='Medium' text={
                  <span className='buttonTextBox'>
                    <span className="material-symbols-outlined icon">arrow_forward</span>
                    Get started
                  </span>} color='Start' route='/login'/>
              </div>
              <div className='buttonLinks' id="btnSparse">
                <Button size='Medium' text={
                  <span className='buttonTextBox'>
                    <span className="material-symbols-outlined icon">arrow_forward</span>
                    How
                  </span>}  color='How' route='/docs?view=start'/>
                <Button size='Medium' text={
                  <span className='buttonTextBox'>
                    <span className="material-symbols-outlined icon">arrow_forward</span>
                    Start
                  </span>} color='Start' route='/login'/>
              </div>
          </div>
        </div>
        <div className='bannerGrid articleText'>

          <h3 className='articleSubTitle bannerGridTitle'><span className="material-symbols-outlined">temp_preferences_custom</span> What will you build?</h3>
          
          <div className='cardGrid'>

            <div className='imageCard'>
              <img src={cowboy} alt="<@:-)"></img>
              <div className='shadow'></div>
            </div>

            <div className='infoCard'>
              <div className='cardTitle textBold'>
                  <p><span className="material-symbols-outlined textIcon">dataset</span> Warehouse</p>
              </div>
              <div className='cardBody'>
                  <p>A data warehouse is a centralized collection of data from several disparate sources.</p>
                  <p>Typically this data pool is consumed by a variety of users with controlled permissions.</p>
                  <p>Use postget as your data warehouse to enhance the reach, visibility, and accessibility of your existing data sources.</p>
              </div>
              <Link to={'/login'} className='appLink textBold'><span className="material-symbols-outlined">play_arrow</span>Try it</Link>
            </div>

            <div className='infoCard'>
              <div className='cardTitle textBold'>
                  <p><span className="material-symbols-outlined textIcon">cloud_done</span>Agile database</p>
              </div>
              <div className='cardBody'>
                  <p>
                    If you have a small to mid-sized front end application with limited back end capabilities, postget is a great option to comfortably scale your operations to the next level.
                    </p>
                    <p>
                    Use postget to scale your data footprint on demand without substantial up front investments in back end design, development, scalability, and maintenance.
                    </p>
              </div>
              <Link to={'/login'} className='appLink textBold'><span className="material-symbols-outlined">play_arrow</span>Build it</Link>
            </div>
            <div className='imageCard'>
              <img src={starface} alt="*_*"></img>
              <div className='shadow'></div>
            </div>

            <div className='imageCard'>
              <img src={explodeface} alt="&:-O"></img>
              <div className='shadow'></div>
            </div>
            <div className='infoCard'>
              <div className='cardTitle textBold'>
                  <p><span className="material-symbols-outlined textIcon">insights</span>Prototype</p>
              </div>
              <div className='cardBody'>
                  <p>
                    Need to build a quick demo, lab, or prototype for a new experimental project?
                  </p>
                  <p>When your project requires development speed, iteration, and adaptability, postget is a great option.</p>
                  <p>Build and deploy large complex data applications programmatically then simply delete them when they're no longer needed.</p>
              </div>
              <Link to={'/login'} className='appLink textBold'><span className="material-symbols-outlined">play_arrow</span>Lab it</Link>
            </div>
          </div>

        </div>

        <div className='bannerList articleText'>
          <h3 className='articleSubTitle bannerGridTitle'><span className="material-symbols-outlined">star</span> What we'll do for you</h3>
          <ul className='featureList textBold'>
            <li><span className="material-symbols-outlined listIcon">check</span> Horizontal data scaling</li>
            <li><span className="material-symbols-outlined listIcon">check</span> Vertical compute scaling</li>
            <li><span className="material-symbols-outlined listIcon">check</span> Database provisioning</li>
            <li><span className="material-symbols-outlined listIcon">check</span> High availability</li>
            <li><span className="material-symbols-outlined listIcon">check</span> Redundancy</li>
            <li><span className="material-symbols-outlined listIcon">check</span> Infrastructure</li>
            <li><span className="material-symbols-outlined listIcon">check</span> Statistics and monitoring</li>
            <li><span className="material-symbols-outlined listIcon">check</span> Security</li>
            <li><span className="material-symbols-outlined listIcon">check</span> Rate limiting</li>
            <li><span className="material-symbols-outlined listIcon">check</span> Best practices and protocols</li>
            <li><span className="material-symbols-outlined listIcon">check</span> Automated documentation</li>
            <li><span className="material-symbols-outlined listIcon">check</span> Access management</li>
          </ul>
        </div>

        <Footer/>
      </div>
    );
  }