import CodeBox from "../../../components/extra/CodeBox";
import InfoBox from "../../../components/extra/InfoBox";
import ParamBox from "../../../components/apidoc/ParamBox";
import ArticleSection from "../../../components/layout/ArticleSection";

const reservedParams = [
    {name:'docId',desc: <span>(length 3-40)
        You <span className="textBold">may</span> set this value on record creation. This is the unique ID used to identify the record within a collection. A <code className="inlineCode textBold">docId</code> can only contain letters, numbers, underscores, and dashes such as <code className="inlineCode textBold">(A-Z,a-z,0-9,_,-)</code>. If you do not set <code className="inlineCode textBold">docId</code> on record creation, one will be generated for you. <span className="textBold">This attribute cannot be changed on the record after creation.</span>
        </span>},
    {name:'exists',desc: <span>A boolean value indicating whether the object currently exists persistently. You <span className="textBold">may not</span> set this value. This value will always be <code className="inlineCode textBold">true</code> in response payloads other than on deletion.</span>},
    {name:"<ownerId>",desc: <span>This value is automatically recorded on records when the parent route is flagged with the <code className="inlineCode textBold">recordOwner</code> attribute. You <span className="textBold">may not</span> set this value.</span>}
]
export default function SpecialDoc() {
    return (
        <div className="Article apiDoc">
            <div className="articleCentered">
                <h3 className="articleTitle">Special Cases</h3>
                <p className="articleText">
                    This page documentations all of our unique quirks, conventions, and special cases. If you're troubleshooting unexpected behavior or looking for cool hacks, you're in the right place.
                </p>
                
                <h3 className="articleSubTitle flexTitle" id="reserved"><span className="material-symbols-outlined icon">front_hand</span>Reserved</h3>
                <p className="articleText">The following attributes are reserved on all of your records</p>
                <ParamBox type="Reserved" params={reservedParams}/>
                <p className="articleText">In addition, the URL query key <span className="inlineCode textBold">appId</span> is reserved and cannot be a valid target for a client query.</p>
                <InfoBox color='Yellow' text={<span><span className="textBold">Special note,</span> the attribute/key naming conventions <span className="textBold"> __name__ </span> <em>(a name proceeded and followed by one or more underscores)</em> and <span className="textBold"> {"<name>"} </span> <em>(a name enclosed by angle brackets)</em> are reserved and should <span className="textBold">NOT</span> be used in your records</span>}/>
                
                <h3 className="articleSubTitle flexTitle" id="nested"><span className="material-symbols-outlined icon">data_array</span>Nested Updates</h3>
                <p className="articleText">You may find that you want to perform an update operation to modify a nested value in either the owner or client API. While you can of course send the full data structure within the <code className="inlineCode textBold">PUT</code> payload, for your convenience, the following shortcuts are available to you.</p>
                <p className="articleText">
                <span className="textBold">Change nested value:</span> Use dots to chain the <code className="inlineCode textBold">JSON</code> path when issuing an update operation as a shortcut. Let's take the following object as an example:
                </p>
                <CodeBox headerView={false} codeTitle={'Starting Example'} codeText={JSON.stringify({'name':'John','Age':33, 'Education':{'Bachelors':true,'Masters':false}},null,4)}/>
                <p className="articleText">Supposing we want to set the Education/Masters value to true, if we issue the following payload via <code className="inlineCode textBold">PUT</code> method, we'll inadvertently delete the Bachelors attribute:</p>
                <CodeBox headerView={false} codeText={JSON.stringify({'Education':{'Masters':true}},null,4)}/>
                <p className="articleText">We can of course issue the following verbose payload via <code className="inlineCode textBold">PUT</code> method to achieve the desired result:</p>
                <CodeBox headerView={false} codeText={JSON.stringify({'Education':{'Bachelors':true,'Masters':true}},null,4)}/>
                <p className="articleText">Since the aforementioned verbose method can become laborious, the following convention (via <code className="inlineCode textBold">PUT</code> method) is available to you as a convenience</p>
                <CodeBox headerView={false} codeText={JSON.stringify({'Education.Masters':true},null,4)}/>
                <h3 className="articleSubTitle flexTitle" id="delete"><span className="material-symbols-outlined icon">delete</span>Field Deletions</h3>
                <p className="articleText">
                    If you simply want to remove one or more specific fields within a document, you can target those fields directly in a common update operation by flagging the fields of interest with a <code className="inlineCode textBold">{'<delete>'}</code> value. Note that this convention can be combined with nested update techniques described above.
                </p>
                <p className="articleText">Here is an example <code className="inlineCode textBold">PUT</code> payload that simply removes the nested Education/Masters field from the example document discussed above.</p>
                <CodeBox headerView={false} codeText={JSON.stringify({'Education.Masters':'<delete>'},null,4)}/>
                <p className="articleText">
                    The expected results of the field deletion:
                </p>
                <CodeBox headerView={false} codeText={JSON.stringify({'name':'John','Age':33, 'Education':{'Bachelors':true}},null,4)}/>

                <h3 className="articleSubTitle flexTitle" id="change"><span className="material-symbols-outlined icon">database</span>Changing Route Schema</h3>
                <p className="articleText">Beyond the application layer, no schema restrictions are enforced on your raw data. For this reason, <span className="textBold">you can update the schema requirements on your routes at any time.</span> The schema configured at the route level only filters incoming data on write operations. No restrictions or transformations will be applied to your data on reads even if returned records do not conform to a common schema.</p>
            </div>
      </div>
    )
}