import './Method.css'
import ParamBox from './ParamBox'
import ResponseCodes from './ResponseCodes'
import CodeExample from '../extra/CodeExample'
import CodeBox from '../extra/CodeBox'
import { useState } from 'react'
import ApiSchema from './ApiSchema'
import { nanoid } from 'nanoid'

export default function Method({method}) {
  const example = [method.url, method.contentType]
  const [expanded, setExpanded] = useState(true)
  const handleExpand = () => {
    setExpanded(prev=>!prev)
  }

  return (
    <div className='routeMethod'>
      <div className='methodTag'>
        <h3 className={`methodName ${method.method}Title`}>{method.method.toUpperCase()} {expanded 
        ? <span className="material-symbols-outlined icon" onClick={handleExpand}>expand_more</span> 
        : <span className="material-symbols-outlined icon" onClick={handleExpand}>chevron_left</span> }</h3>
      </div>

      <div className='methodInfo'>
        <p className='routeMethodDesc articleText'>
          <em>{`"${method.desc}"`}</em>
        </p>

        {expanded && (
          <div className='routeMethodExample'>
            <div className='routeUrl'>
              <CodeExample lines={example}/>
            </div>
            {method.params && method.params.map(param=> <span key={nanoid()}><ParamBox params={param.variables} type={param.type}/></span>)}
            {(method.schema && Object.keys(method.schema).length > 0) && <ApiSchema schema={method.schema}/>}
            {method.info && <p className='routeExpl articleText'>{method.info}</p>}
            <div className='routeCode'>
              {method.code && <CodeBox codeText={method.code} codeTitle={false} headerView={false}/>}
            </div>
            <ResponseCodes responses={method.responses}/>
          </div>
        )}
      </div>

    </div>
  )
}