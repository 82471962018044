import './Nav.css'
import { NavLink } from 'react-router-dom'
import { useAuthContext } from '../../hooks/useAuthContext'
import  Modal  from '../generic/Modal'
import UpdateForm from '../forms/UpdateForm'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
// import Logo from '../../assets/logo192.png'
import Logo from '../../assets/logo_light.png'
import OverNav from './OverNav'

const defaultNavLinks = [
  {title:'Documentation',to:'/docs?view=start',subRoutes:[
    {title:'Getting started',to:'/docs?view=start'},
    {title:'Authentication',to:'/docs?view=auth'},
    {title:'Apps',to:'/docs?view=apps'},
    {title:'Routes',to:'/docs?view=routes'},
    {title:'Clients',to:'/docs?view=clients'},
    {title:'App store',to:'/docs?view=appstore'},
    {title:'Query & serialization',to:'/docs?view=serialization'},
    {title:'Special cases',to:'/docs?view=specialcases'},
    {title:'Error archive',to:'/docs?view=errors'},
    {title:'Pagination',to:'/docs?view=pagination'},
    {title:'Overview',to:'/docs?view=overview'},
    {title:'Complex queries',to:'/docs?view=query'},
    {title:'Export',to:'/docs?view=docex'},
    {title:'Permissions',to:'/docs?view=permissions'},
    {title:'Release',to:'/docs?view=release'},
    {title:'Roadmap',to:'/docs?view=roadmap'}
  ]},
  {title:'AppStore',to:'/appstore'}
]
export default function Nav() {
  const {user} = useAuthContext()
  const [showModal, setShowModal] = useState(false)
  const [showOverNav, setShowOverNav] = useState(false)
  const [navLinks, setNavLinks] = useState([])
  
  const location = useLocation()

  useEffect(() => {
    if (user){
      setNavLinks([
        ...defaultNavLinks,
        {title:'ApiKeys',to:'/apikeys?view=secret'},
        {title:'MyApps',to:'/apps?view=routes',subRoutes:[
          {title:'Routes',to:'/apps?view=routes'},
          {title:'Clients',to:'/apps?view=clients'},
          {title:'Statistics',to:'/apps?view=statistics'},
          {title:' '},
        ]},
        {title:'Settings',to:'/apikeys?view=secret'},
      ])
    } else {
      setNavLinks([
        ...defaultNavLinks,
        {title:'Login',to:'/login'},
        {title:'Signup',to:'/signup'}
      ])
    }
  },[user])

  return (
    <div className={location.pathname.toLowerCase().includes('iframe') ? 'hide navBar' : 'navBar'}>
      { showModal && (<Modal                    
                    setShowModal={setShowModal} 
                    type='form'
                    onConfirm={()=>{}}
                    header={'Account info'}
                    height={50}
                    message={<UpdateForm onLogout={()=>setShowModal(false)}/>}
                    route='#'/>)}
      {showOverNav && (
        <OverNav 
          navLinks={navLinks} 
          handleClose={(showModal=false)=>{
              setShowModal(showModal)
              setShowOverNav(false)
            }
          }
        />
      )}
      <div className='navContainer'>
        <div className={'leftNavLinks'}>
          <div className='navLogo'>
            <NavLink to='/home' onClick={()=>setShowOverNav(false)}>
              <img src={Logo} alt='postget logo' aria-label='postget logo'></img>
            </NavLink>
          </div>
          <ul className='navBarLinks'>
            <li>
              <NavLink  
              to='/docs?view=start' 
              className={({isActive}) => {
                return 'navItem navText'+(isActive ? ' activeRoute' : '')}
              }>
                Documentation
              </NavLink>
            </li>
            <li>
              <NavLink  
              to='/appstore' 
              className={({isActive}) => {
                return 'navItem navText'+(isActive ? ' activeRoute' : '')}
              }>
                AppStore
              </NavLink>
            </li>
            {
              user && (
                <>
                  <li>
                    <NavLink  
                    to='/apikeys?view=secret' 
                    className={({isActive}) => {
                      return 'navItem navText'+(isActive ? ' activeRoute' : '')}
                    }>
                      ApiKeys
                    </NavLink>
                  </li>
                  <li>
                    <NavLink  
                    to='/apps?view=routes' 
                    className={({isActive}) => {
                      return 'navItem navText'+(isActive ? ' activeRoute' : '')}
                    }>
                      MyApps
                    </NavLink>
                  </li>
                </>
              )
            }
          </ul>
        </div>
        <div className='rightNavLinks'>
          {user &&
            <div className='userIdentity' onClick={()=>setShowModal(true)}>
              <div className='userIcon'><p>{(user.displayName || user.email)[0].toUpperCase()}</p></div>
              <span className='userName'>{(user.displayName || user.email)}</span>
            </div>
          }
          {!user &&
            <ul className='loginNavLinks'>
              <li className='loginLink'>
                <NavLink  
                to='/login' 
                className={({isActive}) => {
                  return 'navItem navText'+(isActive ? ' activeRoute' : '')}
                }>
                  Login
                </NavLink>
              </li>
              <li className='signupLink'>
                <NavLink  
                to='/signup' 
                className={({isActive}) => {
                  return 'navItem navText'+(isActive ? ' activeRoute' : '')}
                }>
                  Signup
                </NavLink>
              </li>
            </ul>
          }
        </div>
        <span className={`material-symbols-outlined hamburger textBold inlineButton ${showOverNav && 'inlineBtnSelected'}`} onClick={()=>setShowOverNav(prev => !prev)}>
          menu
        </span>
      </div>
    </div>
  )
}