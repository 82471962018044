import { useEffect, useState } from 'react';
import { Link, useSearchParams, Navigate, useNavigate } from 'react-router-dom';
import './AppIndex.css'
import DropSelect from '../../components/generic/DropSelect';
import SideNav from '../../components/layout/SideNav';
import { useAppContext } from '../../hooks/useAppContext';
import ReactApexChart from 'react-apexcharts'
import ActivityFeed from '../../components/extra/ActivityFeed';
// import ApiDoc from '../../components/extra/ApiDoc';
import ApiUsers from '../../components/extra/ApiUsers';
import ApiDoc from '../../components/apidoc/ApiDoc';
import { hydrateRouteDocData } from '../../util/Util';
import PageNav from '../../components/layout/PageNav';
// side routes: overview, users, stats/history, monetize?

const originalChartOptions = {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    xaxis: {
      categories: [],
    }
}

// get list of last 14 days for xaxis categories... for each of -14 items in history
// if it's in the last 14 days, .hits ... for each user, aggregate .reads and .writes
export default function AppIndex() {
    const {app, setApp, appDocuments, data} = useAppContext()
    const [chartOptions, setChartOptions] = useState({})
    const [chartSeries, setChartSeries] = useState([])
    // const appDocuments = []
    const [routeData, setRouteData] = useState(null)
    const [route, setRoute] = useState(null)
    const [routeGroups, setRouteGroups] = useState(
      {
        preview:[
          {label:'Routes',value:'routes',path:'/apps?view=routes',subPaths:[]},
          {label:'Clients',value:'clients',path:'/apps?view=clients'},{label:'Statistics',value:'statistics',path:'/apps?view=statistics'}
        ]
      }
    )

    const [searchParams, setSearchParams] = useSearchParams();
    const view = searchParams.get('view')
    const focus = searchParams.get('focus')
    const focusName = focus ? focus.charAt(0).toUpperCase() + focus.slice(1) : "N/A"
    const pageRoutes = {
      "routes":[
        {label:`- ${focusName} Index`,path:`/apps?view=routes&focus=${focus}#index`},
        {label:`- ${focusName} Detail`,path:`/apps?view=routes&focus=${focus}#detail`},
      ]
    }

    const navigate = useNavigate()
    
    const getDailyUserHits = () => {
      if (data?.statistics?.dailyStats?.length) {
        let d = new Date()
        d = new Date(
          d.getFullYear(),
          d.getMonth(),
          d.getDate()
        )
        let t = new Date(
          d.getFullYear(),
          d.getMonth(),
          d.getDate()
        )
        t.setDate(t.getDate() + 1)
        if (data.statistics.dailyStats[0].date?.toDate().toISOString().split('T')[0] === d.toISOString().split('T')[0]) {
          return data.statistics.dailyStats[0].hits
        } else if (data.statistics.dailyStats[0].date?.toDate().toISOString().split('T')[0] === t.toISOString().split('T')[0]){
            return data.statistics.dailyStats[0].hits
          }
      }
      return {}
    }

    useEffect(()=>{
      if (appDocuments?.length && !app) {
        setApp({label:appDocuments[0].name, value:appDocuments[0].id})
      }
    },[appDocuments, app])

    useEffect(()=>{
      if (route) {
        setRouteData(hydrateRouteDocData(route))
      }
    },[route])

    useEffect(()=>{
      if (data) {
        setRouteGroups(prev => (
          {
            preview:[{label:'Routes',value:'routes',path:'/apps?view=routes',subPaths: data.routes?.map((route)=>{
              return {label:route.name, value:route.id, path:`/apps?view=routes&focus=${route.id}`}
          }) || [] },
              {label:'Clients',value:'clients',path:'/apps?view=clients'},
              {label:'Statistics',value:'statistics',path:'/apps?view=statistics'}]
          }
        ))
        setRoute(data.routes.filter(route=>route.id === focus)[0])
        
        if (data?.statistics?.dailyStats) {
          const dates = []
          let dateLabels = []
  
          const chartSeries= [
            {
              name: "Writes",
              data: []
            },{
              name: "Reads",
              data: []
            }
          ]
  
          for (let i=13; i>=-1; i--) {
  
              let d = new Date()
              d = new Date(
                d.getFullYear(),
                d.getMonth(),
                d.getDate()
              )
              d.setDate(d.getDate() - i)
              dates.push(d)
              dateLabels.push(d.toISOString().split('T')[0])
                
          }

          const statDict = {}

          data.statistics.dailyStats.length && data.statistics.dailyStats.forEach(element => {
            let reads = 0
            let writes = 0 
            element.hits && Object.keys(element.hits).map(key=>{
              reads += element.hits[key].reads
              writes += element.hits[key].writes
              return
            })
            if (element.date){
              statDict[element.date.toDate().toISOString().split('T')[0]] = {reads, writes}
            }
          })

          for (const dateString of dateLabels) {
            chartSeries[1].data.push(statDict[dateString] ? statDict[dateString].reads || 0 : 0)
            chartSeries[0].data.push(statDict[dateString] ? statDict[dateString].writes || 0 : 0)
          }

          if (chartSeries[0].data[14] === 0 && chartSeries[1].data[14] === 0){
            dateLabels = dateLabels.slice(0,14)
            chartSeries[0].data = chartSeries[0].data.slice(0,14)
            chartSeries[1].data = chartSeries[1].data.slice(0,14)
          } else {
            dateLabels = dateLabels.slice(1,15)
            chartSeries[0].data = chartSeries[0].data.slice(1,15)
            chartSeries[1].data = chartSeries[1].data.slice(1,15)
          }
          originalChartOptions.xaxis.categories = dateLabels
          setChartOptions(originalChartOptions)
          setChartSeries(chartSeries)
        } else {
          setChartSeries([])
        }
      } else {
        setChartSeries([])
      }
    },[data, focus])

    if (view === 'routes' && (!focus || !data.routes.map((r)=>r.id).includes(focus)) && data.routes.length > 0){
      return <Navigate to={`/apps?view=routes&focus=${data.routes[0].id}`}/>
    }

    if (appDocuments?.length === 0) {
      return (
        <>
          <SideNav routeGroups={routeGroups}/>
          <div className={'appIndex Article'}>
            <div className='articleCentered'>
                  <h3 className='articleTitle flexTitle'><span className="material-symbols-outlined icon largeIcon">widgets</span>You don't have any apps yet... <Link className='appLink bigLink' to='/docs?view=start'>Get started</Link></h3>
            </div>
          </div>
        </>
      )
    }
    return (
      <>
        <SideNav routeGroups={routeGroups}/>
        <div className={`appIndex ${ (view === 'routes' && routeData) ? 'splitArticle' : 'Article'}`}>
          <div className='articleHead'>
            <div className='appIndexHeader'>
              <div className='appIndexTitle'>
                <h3 className='articleTitle'>{app?.label.slice(0,(Math.min(20,app.label.length)))+' ' || 'My Apps '}
                {app && <span className="primaryText smallText limitTag">{`Limit: ${data.app.limit}`}</span>}
                </h3>
                <div className='appSelect'>
                  <DropSelect 
                  onChange={(e) => e.value === '__select__' ? setApp(null) : setApp(e)} 
                  value={ app ? {label:app.label,value:app.value} : null} 
                  options={appDocuments ? appDocuments.map((doc)=>{return{label:doc.name,value:doc.id}}): {}}
                  />
                </div>
              </div>
              <p className='articleText textLight appDesc'><em>{`"${data.app.description}"`}</em></p>
            </div>
          </div>

          <div className={view === 'routes' ? 'flexDiv apiDocContainer' : ''}>

            <div className='articleBody'>
              <div className="articleCentered">

                {!data.app.id && appDocuments?.length > 0  && (<div>
                  <p className='articleText textLight'>
                      Select an app to preview.
                  </p>
                </div>)}
                {view==='statistics' && (
                  <>
                    <h3 className='articleSubTitle'>Statistics</h3>
                    <div className='appChart'>
                      {chartOptions.chart &&  <ReactApexChart options={chartOptions || {}} series={chartSeries || []} type="line" height={350} />}
                    </div>
                    <ActivityFeed feed={data?.statistics?.feed ? [].concat(data.statistics.feed) : []} reverse={false}/>
                  </>
                )}
                {view === 'clients' && (
                  <div className='clientTable'>
                    <h3 className='articleSubTitle'>Clients</h3>
                    <ApiUsers userData={data?.consumers || []} hits={getDailyUserHits()}/>
                  </div>
                )}
                {/* overview view */}
                {view === 'routes' && (
                  <>
                    {routeData && (
                      <div className='appInfo'>
                        <ApiDoc
                            dropSelect={
                              <div className='appSelect routeSelect'>
                                <DropSelect 
                                onChange={(e) => e.value !== '__select__' && navigate(e.value)} 
                                value={ routeData?.id ? {label:routeData.name,value:`/apps?view=routes&focus=${routeData.id}`} : null} 
                                options={data.routes?.map((route)=>{
                                      return {label:route.id, value:`/apps?view=routes&focus=${route.id}`
                                    }
                                })}
                                />
                              </div>
                            }
                            apiData={routeData}
                          />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>

            {view === 'routes' && <PageNav routeGroups={pageRoutes}/>}
          </div>


        </div>
      </>
    )
  }