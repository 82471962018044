import AuthForm from '../../components/forms/AuthForm';
import './Login.css'

export default function Login() {
    return (
      <div className="loginPage navTop">
        {/* <h2 className="articleSubTitle">Login</h2> */}
        <AuthForm action='login' buttonText='Login' title='Welcome back!'/>
      </div>
    );
  }