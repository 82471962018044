import ContactForm from "../../components/forms/ContactForm";

export default function Contact() {
    return (
      <div className="loginPage navTop">
        <div className="articleCentered">
          <div className="contactUs">
            <ContactForm/>
          </div>
        </div>
      </div>
    );
  }