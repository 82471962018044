export const useKeyGeneric = () => {

    const resetKey = async (key) => {
        try {
            const res = await fetch(
                // 'http://localhost:8080/api/v1/keys/reset',
                'https://app.postget.dev/api/v1/keys/reset',
                {
                    method: 'POST',
                    headers: {
                        Authorization:`Basic ${key}`,
                        Origin: 'https://postget.dev',
                        'Access-Control-Request-Method': 'POST',
                        'Access-Control-Request-Headers': 'Content-Type, Authorization'
                    }
                }
            )

            const json = await res.json()
            const newKey = json.response?.apiKey
            return newKey

        } catch {
            return null
        }
    }

    return {resetKey}
}