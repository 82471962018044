import { Link } from "react-router-dom";
import CodeBox from "../../../components/extra/CodeBox";
import InfoBox from "../../../components/extra/InfoBox";
import QueryParams from "../../../components/extra/QueryParams";

export default function ClientQueryDoc() {
    return (
    <div className="Article apiDoc">
        <div className="articleCentered">
            <h3 className="articleTitle">Client Query</h3>
            <p className="articleText">
                The client API supports complex URL queries. In addition to the standard query parameters, you will be able to query against <span className="textBold">any of the custom attributes</span> found on your records. We also support a broad range of operators in the URL query section
            </p>
            <h3 className="articleSubTitle flexTitle" id="query"><span className="material-symbols-outlined icon">search</span>Query parameters</h3>
            <QueryParams arg={true}/>
            <p className="articleText">
                <span className="textBold">You can query by any custom key found at any level of the schema associated with your route. </span>
            </p>
            <div className="">
                <p className="articleText">
                    {'For example, '}
                    <code className="inlineCode textBold">{'?Age>=30'}</code> and <code className="inlineCode textBold">{'?name="John"'}</code> are considered valid queries against the route which hosts this record.
                </p>
                <p className="articleText">
                    Check this out: <code className="inlineCode textBold">{'?Education.Bachelors=true'}</code> is the proper convention for querying against a nested keys as represented in the below example.
                </p>
                <CodeBox
                codeText={JSON.stringify({"name": "John","Age": 33,"Education": {"Bachelors": true,"Masters": false}},null,4)}
                headerView={false}
                />
            </div>

            <p className="articleText"><span className="textBold">Query serialization: </span>
            What if you want to query against complex data types? In the above example, we wrap strings in double quotes to denote the proper interpretation. In all other dynamic cases, the data is interpreted as a non string if possible. For a review on serialization conventions including datetime objects, see <Link className="appLink textBold" to='/docs?view=serialization'>Serialization</Link>
            </p>

            <p className="articleText"><span className="textBold">Query operations: </span>
            When executing a query in the URL, the following operations are supported: <code className="inlineCode textBold">{' =, !=, >=, <= '}</code>. These operations represent <span className="textBold">equal to</span>, <span className="textBold">not equal to</span>, <span className="textBold">greater or equal to</span>, and <span className="textBold">lesser or equal to</span> respectively.
            You may combine query terms to make complex filters.
            </p>
            <InfoBox color='Yellow' text={"Compute demands increase with query complexity so response times may increase in proportion to query verbosity."}/>
        </div>
    </div>
    )
}