import './ParamBox.css'
import { nanoid } from 'nanoid'

export default function ParamBox({params, type='URL path'}) {

  return (
  <div className='routeParams articleTextSmall'>
    <div className='routeParamsHeader'>
      <h4><span className="material-symbols-outlined icon">data_object</span> {type} parameters</h4>
    </div>
    <div className='routeParamsBody'>
      <ul className='paramsList'>
        {params.map((param)=>{
        return <li className='paramsItem' key={nanoid()}>
          <span className='paramName'>{param.name}{param.required && <span>{' '}<span className='requiredTag'>{'[REQUIRED]'}</span></span>}:</span>
          <span className='paramDesc'> {param.desc}</span>
        </li>
        })}
      </ul>
    </div>
  </div>)

}