import { nanoid } from "nanoid"
import jsf from 'json-schema-faker';

const methodColorMap = {
    GET:'Success',
    PUT:'Warning',
    POST:'Primary',
    DELETE:'Danger',
    OPTIONS:'Primary'
}

const handle_iso_schema = (data) => {
  if (data instanceof Array) {
    return data.map(d=>handle_iso_schema(d))
  } else if (data && data instanceof Object) {
    if (data.properties && data.properties instanceof Object && Object.keys(data.properties).includes("<dateTime>")) {
      data.additionalProperties = false
      data.properties['<dateTime>'].enum = ['2023-01-01T12:12:35.599743']
      // return data
    }
    for (const [key, value] of Object.entries(data)) {
      data[key] = handle_iso_schema(value)
    }
    return data
  }
  return data
}

const jsonAbstractions = (jsonData) => {
  const transformKeys = {
    '"<object>"':"{ ... }",
    '"<jsonSchema>"':"{ ...jsonSchema }",
    '"<...>": "<...>",':"..."
  }
  Object.keys(transformKeys).map((k)=>{
    jsonData = jsonData.replaceAll(k,transformKeys[k])
  })
  return jsonData
}

const hydrateRouteDocData = (route, client=true) => {
  // input a route doc
  // output data ready for populating apiDocs

  const docIdExpl = <span className="articleText"><span className="textBold">OPTIONALLY</span> include a 'docId' parameter to control the record ID (length 3-45). A docId can only contain letters, numbers, underscores, and dashes such as <code className="inlineCode textBold">(A-Z,a-z,0-9,_,-)</code> If a valid docId is not present, one will be generated for you <span className="textBold">A docId cannot be changed once it is generated</span></span>

  let routeSchema = {type: "object", properties: {}}
  
  try {
    routeSchema = handle_iso_schema(structuredClone(route.schema))
  } catch {}
  
  const patchObj = route.exampleObj || jsf.generate(routeSchema)
  patchObj.docId = route.exampleObj?.docId || 'doc_1'
  patchObj.exists = true
  const putResp = {response: {...patchObj}}

  const responses = [
    {code:'200',color:'success',desc:'Success'},
    {code:'400',color:'danger',desc:'Client error'},
    {code:'403',color:'danger',desc:'Forbidden'},
    {code:'404',color:'danger',desc:'Not found'},
    {code:'405',color:'danger',desc:'Method rejected'},
    {code:'500',color:'danger',desc:'Server error'},
  ]
  const indexMethods = []
  const getCode = {
    "response": [
          {
              "docId": "doc_1",
              "<...>": "<...>",
              "exists": true
          },
          {
              "docId": "doc_2",
              "<...>": "<...>",
              "exists": true
          }
      ]
  }
  const deleteCode = {
      "response": {
          "success": true
      }
  }

  if (route.methods.includes('GET')) {
    const params = [
      {
        type:'URL query',
        variables: [
          {name:'limit',desc: 'An integer within range 0-500 to limit response payload'},
          {name:'start',desc: "A string recordId used to offset the returned response payload"},
          {name:'orderBy',desc: 'The attribute (string) to sort the response payload by'},
          {name:'orderDir', desc: "A string 'asc' or 'desc' specifying the sort order"},
          {name:'<arg>', desc: "Any root key contained in the schema"},
        ]
      }
    ]
    indexMethods.push({
      method:'get',
      desc:`Read a list of existing ${client ? 'records' : route.name.toLowerCase()}`,
      url: route.indexUrl || (client ? 'https://app.postget.dev/api/v1/client/' : 'https://app.postget.dev/api/v1/owner/') +route.id,
      contentType:'Content-Type: application/json',
      params,
      info:<span>The following operations are supported: <code className="inlineCode">{'=, !=, >=, <='}</code></span>,
      code:jsonAbstractions(JSON.stringify(getCode,null,4)),
      responses
    })
  }
  if (route.methods.includes('POST')) {
    indexMethods.push({
      method:'post',
      desc: client ? 'Write a new record to this route' : `Create a new live ${route.id.slice(0,-1)} entity`,
      url: route.indexUrl || (client ? 'https://app.postget.dev/api/v1/client/' : 'https://app.postget.dev/api/v1/owner/') +route.id,
      contentType:'Content-Type: application/json',
      params:false,
      schema:route.schema || {},
      info: docIdExpl,
      code: JSON.stringify(putResp,null,4),
      responses
    })
  }
  
  const detailMethods = [
    {
      method:'get',
      desc:`Read a target ${client ? 'record' : route.id.slice(0,-1)} and all of its attributes`,
      url: route.detailUrl || <span>https://app.postget.dev/api/v1/{client ? "client" : "owner"}/{route.id}/<span className='textBold'>{'<docId>'}</span></span>,
      contentType:'Content-Type: application/json',
      params:false,
      info: false,
      code: JSON.stringify(putResp,null,4),
      responses
    },
    {
      method:'put',
      desc: client ? 'Update one or more attributes on a target record' : `Update this live ${route.id.slice(0,-1)} entity`,
      url: route.detailUrl || <span>https://app.postget.dev/api/v1/{client ? "client" : "owner"}/{route.id}/<span className='textBold'>{'<docId>'}</span></span>,
      contentType:'Content-Type: application/json',
      params:false,
      info:`Payload should contain some or all of the attributes defined on this ${client ? "route's" : "resource's"} schema other than docId`,
      code: JSON.stringify(putResp,null,4),
      responses
    },
    {
      method:'delete',
      desc:'Permanently delete the target ' + (client ? 'record' : route.id.slice(0,-1)),
      url: route.detailUrl || <span>https://app.postget.dev/api/v1/{client ? "client" : "owner"}/{route.id}/<span className='textBold'>{'<docId>'}</span></span>,
      contentType:'Content-Type: application/json',
      info:<span><span className="textBold">This is permanent! </span>Do not delete anything you aren't willing to part with forever!</span>,
      code:JSON.stringify(deleteCode,null,4),
      responses
    }
  ]
  
  const routes = [
    {
      title: route.name + ' index',
      description: `The index resource is used to read sets of existing ${client ? 'records' : route.name.toLowerCase()} or write new ones to the collection`,
      baseUrl: route.indexUrl || (client ? 'https://app.postget.dev/api/v1/client/' : 'https://app.postget.dev/api/v1/owner/') +route.id,
      params: route.indexParams || false,
      methods: indexMethods
    },
    {
      title: route.name + ' detail',
      description: 'The detail resource is used to read, update, or delete a target '+ (client ? 'record' : route.id.slice(0,-1)),
      baseUrl: route.detailUrl || <span>https://app.postget.dev/api/v1/{client ? "client" : "owner"}/{route.id}/<span className='textBold'>{'<docId>'}</span></span>,
      params: route.detailParams || [
        {
          type:'URL path',
          variables:[{name:'docId',required:true,desc:'The unique docId of the target '+ (client ? 'record' : route.id.slice(0,-1))},]
        }
      ],
      methods: detailMethods
    }
  ]
  const apiData = {
    name:route.name,
    id:route.id,
    resourceDesc:route.description,
    links: route.links || [{to:'/docs?view=query',desc:'Review complex query'},{to:'/docs?view=errors',desc:'Review error messages'}],
    routes
  }

  return apiData
}

const transformSchema = (schema) => {

    const wrapKeys = (data) => {
        // if (Array.isArray(data)) {
        //     return data.map(d=>wrapKeys(d))
        // }
        if (typeof data === 'object') {
            const newData = {}
            Object.keys(data).forEach((key)=>{
                if (key === 'required') {
                  newData[`<span1Danger>${key}<span2>`] = data[key]
                } else {
                  newData[`<span1>${key}<span2>`] = data[key]
                }
            })
            return newData
        }
        return data
    }

    let jsonData = jsonAbstractions(JSON.stringify(wrapKeys(schema),null,4))
    const resultingComponents = []

    jsonData.split('<span2>"').forEach((chunk) => {
        if (chunk.includes('"<span1Danger>')) {
          const subChunks = chunk.split('"<span1Danger>')
          resultingComponents.push(<span key={nanoid()}>{subChunks[0]}</span>)
          resultingComponents.push(<span key={nanoid()} className="textBold textDanger">"{subChunks[1]}"</span>)
        } else if (chunk.includes('"<span1>')) {
            const subChunks = chunk.split('"<span1>')
            resultingComponents.push(<span key={nanoid()}>{subChunks[0]}</span>)
            resultingComponents.push(<span key={nanoid()} className="textBold">"{subChunks[1]}"</span>)
        } else {
            resultingComponents.push(<span key={nanoid()} >{chunk}</span>)
        }
    })
    
    return resultingComponents
}

function isStrongPwd(password) {
 
  var regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!?@#$%&*()]).{8,}/
  var validPassword = regExp.test(password)
  return validPassword

}

export {methodColorMap, transformSchema, isStrongPwd, hydrateRouteDocData}