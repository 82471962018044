import './UpdateForm.css'
import { useState, useEffect } from 'react'
import { useUpdate } from '../../hooks/useUpdate'
import { useLogout } from '../../hooks/useLogout'
import Tag from '../generic/Tag'
import SimpleButton from '../generic/SimpleButton'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useKey } from '../../hooks/useKey'
import { useDocument } from '../../hooks/useDocument'

export default function UpdateForm({onLogout}) {
    const [email, setEmail] = useState('')
    const [confirmEmail, setConfirmEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [username, setUsername] = useState('')
    const [error,setError] = useState(null)
    const [resetEmail, setResetEmail] = useState(false)
    const [resetPassword, setResetPassword] = useState(false)
    const [suspendAccount, setSuspendAccount] = useState(false)
    const [suspendConfirm, setSuspendConfirm] = useState('')

    const {user} = useAuthContext()
    const { document: userDoc } = useDocument('users',user.uid)

    const {error: keyError, disableKey, enableKey} = useKey()
    const {update, isPending, error: updateError} = useUpdate()
    const {logout} = useLogout()

    const handleEnable = async (e) => {
        setError(null)
        e.preventDefault()
        await enableKey()
        if (keyError) {
            setError(keyError)
        }
    }

    const handleSubmit = async (e) => {
        setError(null)
        e.preventDefault()
        const data = {}
        if (suspendConfirm.toLowerCase() === 'delete'){
            const success = await disableKey()
            if (success) {
                logout()
                return
            }
            if (keyError) {
                setError(keyError)
            }
        }
        if (email.length > 0) {
            if (email !== confirmEmail) {
                setError('Emails do not match')
                return
            }
            data.email = email
        }
        if (password.length > 0) {
            if (password !== confirmPassword) {
                setError('Passwords do not match')
                return
            }
            data.password = password
        }
        if (username.length > 0 ) {
            data.username = username
        }
        update(data)
    }

    useEffect(()=>{
        setError(updateError)
    },[updateError])

    return (
        <div className='updateForm'>
            <div className='userInfo'>
                <p className='articleText'>
                    <span className='textBold'>Email: </span>
                    <span className='value'>{user.email}</span>
                    <span
                    className={`appLink floatRight ${resetEmail && 'dangerLink'}`} 
                    onClick={()=>{
                        if (resetPassword) {
                            setResetPassword(false)
                        }
                        if (suspendAccount) {
                            setSuspendAccount(false)
                        }
                        setResetEmail(prev => !prev)   
                    }}>
                        { resetEmail ? 'cancel' : 'edit'}
                    </span>
                </p>
                {resetEmail && (
                <form onSubmit={handleSubmit}>
                    <label>
                        <span>email</span>
                        <input 
                        type="email"
                        onChange={(e)=>setEmail(e.target.value)}
                        required={true}
                        value={email}
                        />
                    </label>
                    <label>
                        <span>confirm email:</span>
                        <input 
                        type="email"
                        required={true}
                        onChange={(e)=>setConfirmEmail(e.target.value)}
                        value={confirmEmail}
                        />
                    </label>
                    {error && <Tag color='Danger' text={error}/>}
                    {!isPending && <button className="btn btnPrimary">Update email</button>}
                    {isPending && <button disabled className="btn btnPrimary">Loading</button>}
                </form>
            )}
                <p className='articleText'>
                    <span className='textBold'>Password: </span>
                    <span className='value'>{'**********'}</span> 
                    <span 
                    className={`appLink floatRight ${resetPassword && 'dangerLink'}`}
                    onClick={()=>{
                        if (resetEmail) {
                            setResetEmail(false)
                        }
                        if (suspendAccount) {
                            setSuspendAccount(false)
                        }
                        setResetPassword(prev => !prev)   
                    }}
                    >
                        { resetPassword ? 'cancel' : 'edit'}
                    </span>
                </p>
                {resetPassword && (
                    <form onSubmit={handleSubmit} className='margBottom'>
                        <label>
                            <span>password</span>
                            <input 
                            type="password"
                            onChange={(e)=>setPassword(e.target.value)}
                            required={true}
                            minLength={8}
                            maxLength={40}
                            value={password}
                            />
                        </label>
                        <label>
                            <span>confirm password:</span>
                            <input 
                            type="password"
                            onChange={(e)=>setConfirmPassword(e.target.value)}
                            required={true}
                            minLength={8}
                            maxLength={40}
                            value={confirmPassword}
                            />
                        </label>
                        {error && <Tag color='Danger' text={error}/>}
                        {!isPending && <button className="btn btnPrimary">Update password</button>}
                        {isPending && <button disabled className="btn btnPrimary">Loading</button>}
                    </form>
                )}

                <p className='articleText'>
                    <span className='textBold'>Status: </span>{ userDoc?.disabled 
                        ?  <span className='dangerText value'>Disabled</span>
                        : <span className='successText value'>Active</span> }
                    <span 
                    className={`appLink floatRight ${suspendAccount && 'dangerLink'}`}
                    onClick={()=>{
                        if (resetEmail) {
                            setResetEmail(false)
                        }
                        if (resetPassword) {
                            setResetPassword(false)
                        }
                        setSuspendAccount(prev => !prev)   
                    }}
                    >
                        { suspendAccount ? 'cancel' : 'edit'}
                    </span>
                </p>
                {suspendAccount && (userDoc?.disabled
                    ?(
                        <form onSubmit={handleEnable} className='margBottom'>
                            {error && <Tag color='Danger' text={error}/>}
                            {!isPending && <button className="btn btnPrimary">Enable account</button>}
                            {isPending && <button disabled className="btn btnDanger">Loading</button>}
                        </form>
                    )
                    : (
                        <form onSubmit={handleSubmit} className='margBottom'>
                            <label>
                                <span>Type DELETE to confirm</span>
                                <input 
                                type="text"
                                onChange={(e)=>setSuspendConfirm(e.target.value)}
                                required={true}
                                value={suspendConfirm}
                                />
                            </label>
                            {error && <Tag color='Danger' text={error}/>}
                            {!isPending && <button className="btn btnDanger">Suspend account</button>}
                            {isPending && <button disabled className="btn btnDanger">Loading</button>}
                        </form>
                    )
                )}

                <div className='logoutBtnContainer'>
                <SimpleButton color={'Dark'} onClick={
                    ()=>{
                        onLogout()
                        logout()}} text='logout' icon='logout'/>
                </div>
            </div>
        </div>
    )
}