import { useReleaseDoc } from '../../../hooks/useReleaseDoc'
import { nanoid } from 'nanoid'

export default function ReleaseDoc() {
    const { releaseDoc } = useReleaseDoc()
    return (<div className="release Article">
        <div className="articleCentered">
            <h3 className="articleTitle">Release History</h3>
            <p className='articleText'><em>This is the postget release history page! Check in here to find out about
                new features, enhancements, and bug fixes.</em></p>
                <ul className='releaseList'>
                {
                    releaseDoc?.releases.map((release)=>{
                        return (
                            <li key={nanoid()}>
                                <h3 className='articleSubTitle '>
                                    <span className="material-symbols-outlined center icon greenIcon">check_circle</span>
                                    <span className='center'>{release.name}</span>
                                    <span className='releaseDate center'>v{release.version}</span>
                                </h3>
                                <div className='releaseNotes'>
                                    {release.notes.map((note)=><p className='articleText'>{note}</p>)}
                                </div>
                                <ul className='articleText subul'>
                                    <li>
                                        <span className='textBold'>Release date: </span>
                                        {release.releaseDate.toDate().toISOString().split('T')[0]} 
                                    </li>
                                    <li>
                                        <span className='textBold'>Bug fix: </span>
                                        {release.bugFix.map(fix=><span>{fix}</span>)}
                                    </li>
                                    <li>
                                        <span className='textBold'>New features: </span>
                                        {release.newFeatures.map(feature=><span key={nanoid()}>{feature}; </span>)}
                                    </li>
                                </ul>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    </div>)
}