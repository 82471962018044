import { useState } from "react"

export default function CopyButton({className,text}) {
    
    const [clicked, setClicked] = useState(false)

    const handleClick = (e) => {
        e.stopPropagation()
        navigator.clipboard.writeText(text)
        setClicked(true)
        return setTimeout(() => setClicked(false), 2000)
      }

    return (
        <>
            {clicked && (
                <span className={`material-symbols-outlined ${className}`}>
                    done
                </span>
            )}
            {!clicked && (
                <div 
                className={className}
                onClick={handleClick}>
                    copy
                </div>
            )}
        </>
    )
}