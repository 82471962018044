import './AppStore.css'
import AppGallery from '../../components/generic/AppGallery'
import {usePublicApps} from '../../hooks/usePublicApps'
import {useAliases} from '../../hooks/useAliases'
import {useSubscribe} from '../../hooks/useSubscribe'
import { useEffect, useState } from 'react'
import { useKeyGeneric } from '../../hooks/useKeyGeneric'

export default function AppStore() {
    const {apps, error: appsError} = usePublicApps()
    const {aliases, error: aliasesError} = useAliases()
    const {subscribe} = useSubscribe()
    const [appData, setAppData] = useState({})
    const {resetKey} = useKeyGeneric()

    const handleReset = async (key, appPath) => {

        const newKey = await resetKey(key)
        if (newKey) {
            setAppData((prev) => {
                let newState = Object.assign({}, prev)
                newState[appPath].alias.apiClient.apiKey = newKey
                return newState
            })
        }
    }

    const handleSubscribe = async (appPath) => {
        subscribe(appPath)
    }

    useEffect(() => {
        const appHash = {}
        apps.forEach((app)=>{
            appHash[[app.ownerId,app.docId].join("/")] = app
        })
        aliases.forEach((a)=>{
            if (appHash[a.appPath]) {
                appHash[a.appPath].alias = a
            }
        })
        setAppData(appHash)

    },[apps, aliases])

    return (
        <div className="appStore Article ArticleNoNav">
            <div className="articleCentered">
                <h3 className='articleTitle'>App Store</h3>

                <p className='articleText'>Browse all public apps created on postget here! Subscribe to any app to create an alias key. See something that inspires you? Build your own apps and mark them as public for automatic hosting.</p>
                <p className='textBold articleText'>Note: search filter works against app name substrings and/or owner predefined tags (CaPs ignored).</p>
                <AppGallery appData={appData} handleReset={handleReset} handleSubscribe={handleSubscribe}/>
            </div>
        </div>
      )
    }