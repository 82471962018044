import { useEffect, useState } from "react"
import { useAuthContext } from './useAuthContext'
import { useDocument } from "./useDocument"

export const useAliases = () => {
    const {user} = useAuthContext()
    const [error, setError] = useState(null)
    const [aliases, setAliases] = useState([])
    const { document: userDoc } = useDocument('users',user?.uid)

    useEffect(() => {

        const run = async () => {
            
            const key = userDoc?.apiClient?.apiKey
            if (!key) return

            try {
                const res = await fetch(
                    // 'http://localhost:8080/api/v1/store/aliases',
                    'https://app.postget.dev/api/v1/store/aliases',
                    {
                        method: 'GET',
                        headers: {
                            Authorization:`Basic ${key}`,
                            Origin: 'https://postget.dev',
                            'Access-Control-Request-Method': 'GET',
                            'Access-Control-Request-Headers': 'Content-Type, Authorization'
                        }
                    }
                )
    
                const json = await res.json()
                const aliasList = typeof json.response === "object" && json.response
    
                if (aliasList) {
                    setAliases(aliasList)
                } else {
                    setError(json.response)
                }
    
            } catch {
                setError('Unable to fetch aliases')
            }
        }

        run()
    
    // since userDoc is a dependency, this refreshes when a new alias ref is created
    }, [userDoc])

    return {aliases, error}
}