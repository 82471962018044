import './PubApp.css'
import SideNav from '../../components/layout/SideNav';
import ApiDoc from '../../components/apidoc/ApiDoc';
import { hydrateRouteDocData } from "../../util/Util";
import PageNav from '../../components/layout/PageNav';
import { useEffect, useState } from 'react';
import { useSearchParams, Navigate, useNavigate } from 'react-router-dom';
import { usePubApp } from '../../hooks/usePubApp';
import { nanoid } from 'nanoid'
import DropSelect from '../../components/generic/DropSelect';

export default function PubApp() {
    const [appPath, setAppPath] = useState(window.location.href.split("appstore")[1]?.split("?")[0])
    const {data} = usePubApp(appPath.slice(1,).split("/"))
    const [routeData, setRouteData] = useState(null)
    const [route, setRoute] = useState(null)
    const [routeGroups, setRouteGroups] = useState(
      {
        preview:[
          {label:'Overview',value:'overview',path:`/appstore${appPath}?view=overview`},
          {label:'Routes',value:'routes',path:`/appstore${appPath}?view=routes`,subPaths:[]}
        ]
      }
    )
    const [searchParams, setSearchParams] = useSearchParams();
    const view = searchParams.get('view')
    const focus = searchParams.get('focus')
    const navigate = useNavigate();

    const pageRoutes = {
      "routes":[
        {label:`- ${route && (route.name.charAt(0).toUpperCase() + route.name.slice(1,))} Index`,path:`/appstore${appPath}?view=routes&focus=${focus}#index`},
        {label:`- ${route && (route.name.charAt(0).toUpperCase() + route.name.slice(1,))} Detail`,path:`/appstore${appPath}?view=routes&focus=${focus}#detail`},
      ]
    }

    useEffect(()=>{
      if (route) {
        route.id = route.docId
        setRouteData(hydrateRouteDocData(route))
      }
    },[route])

    useEffect(()=>{
      if (data) {
        setRouteGroups(prev => (
          {
            preview:[
              {label:'Overview',value:'overview',path:`/appstore${appPath}?view=overview`},
              {label:'Routes',value:'routes',path:`/appstore${appPath}?view=routes`,subPaths: data.routes?.map((route)=>{
              return {label:route.name, value:route.docId, path:`/appstore${appPath}?view=routes&focus=${route.docId}`}
          }) || [] }]
          }
        ))
        setRoute(data.routes.filter(route=>route.docId === focus)[0])
      }
    },[data, focus])

    const selectVal = {
      label: view === "overview" ? "Overview" : routeGroups?.preview?.find((x)=>x.value==="routes").subPaths?.find((x)=>x.value===focus)?.label, 
      value: view === "overview" ? "overview" : routeGroups?.preview?.find((x)=>x.value==="routes").subPaths?.find((x)=>x.value===focus)?.value, 
    }

    if (view === 'routes' && (!focus || !data.routes.map((r)=>r.docId).includes(focus)) && data.routes.length > 0){
      return <Navigate to={`/appstore${appPath}?view=routes&focus=${data.routes[0].docId}`}/>
    }
    return (
    <div className='pubApp'>
      <SideNav routeGroups={routeGroups}/>
      {(view === "routes" && !routeData) && (
        <div className='Article'>
          <p className='articleText'>{"< Routes loading or not found... >"}</p>
        </div>
      )}
      {(view === "routes" && routeData) && (
      <div className='flexDiv apiDocContainer'>
        <div className="splitArticle apiDoc">
          <div className='articleHead'></div>
            <div className='articleBody'>
                <div className="articleCentered">
                    {routeData && (
                      <ApiDoc 
                      apiData={routeData}
                      dropSelect={
                        <div className='appSelect routeSelect'>
                          <DropSelect 
                          onChange={(e) => e.value !== "__select__" && (e.value === 'overview' ? navigate("/appstore"+appPath+"?view=overview") : navigate("/appstore"+appPath+`?view=routes&focus=${e.value}`))} 
                          value={selectVal} 
                          options={[{label:"Overview",value:"overview"},...(routeGroups?.preview?.find((x)=>x.value==="routes").subPaths || [])]}
                          />
                        </div>
                      }
                      />
                    )}
                </div>
            </div>
        </div>
        <PageNav routeGroups={pageRoutes}/>
      </div>
      )}
      {view === "overview" && (
          <div className='Article'>
            <div className='appHeader'>
              <h3 className='articleTitle'>{data.app.name} <span className="primaryText smallText limitTag">{`Limit: ${data.app.limit}`}</span></h3>
              <div className='appSelect'>
                <DropSelect 
                onChange={(e) => e.value !== "__select__" && (e.value === 'overview' ? navigate("/appstore"+appPath+"?view=overview") : navigate("/appstore"+appPath+`?view=routes&focus=${e.value}`))} 
                value={ selectVal} 
                options={[{label:"Overview",value:"overview"},...(routeGroups?.preview?.find((x)=>x.value==="routes").subPaths || [])]}
                />
              </div>
            </div>
            <p className='articleText appDesc'>"{data.app.description}" <span className='textBold'>-{data.app.author?.charAt(0).toUpperCase()+data.app.author?.slice(1,)}</span></p>
            {data?.app?.tags?.length > 0 && (
              <div className='appTags'>
                {data.app.tags.map((tag)=>{
                  return <div className='appTag' key={nanoid()}>{tag}</div>
                })}
              </div>
            )}
            <h3 className='articleSubTitle'>Default permissions</h3>
            <div className='appPermissions'></div>
            <p className='articleText'>The default route permissions are listed below. If you require specific permission overrides, please contact the administrator at <span className='textBold'>{data.app.contactEmail || "admin@postget.dev"}</span>.</p>
            <div className='appPermissionList'>
              {data?.routes?.map((route)=>{
                return <div className='appPermission' key={nanoid()}>
                  <h3 className='permissionTitle flexTitle'><span className="material-symbols-outlined icon">lock</span>{route.name}</h3>
                  <div className='permissionItems'>{(data.app?.permissions[route.docId] || []).map((p)=>
                      <span key={nanoid()} className='permissionItem'>{p}</span>)}</div>
                </div>
              })}
            </div>
          </div>
        )}
    </div>
    )
  }