import { useState } from 'react'
import { auth } from '../../firebase/config'
import {useLocation} from "react-router-dom"
import { Link } from 'react-router-dom'
import { isStrongPwd } from '../../util/Util'
import Tag from '../../components/generic/Tag'

export default function Reset(props) {
    const [message,setMessage] = useState(null)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [error, setError] = useState(null)
    const [reset, setReset] = useState(false)

    let location = useLocation()
    const mode = new URLSearchParams(location.search).get("mode")
    const oobCode = new URLSearchParams(location.search).get("oobCode")

    const handleSubmit = async (e) => {
        e.preventDefault() 
        setError(null)
        try {
            await auth.sendPasswordResetEmail(email)
        } catch (err) {
            setEmail('')
            setMessage('email sent!')
        }
        setEmail('')
        setMessage('email sent!')
    }

    const handleReset = async (e) =>{
        setError(null)
        e.preventDefault()
        if (!isStrongPwd(password)) {
            setError('Password must be 8-30 characters and must contain one lowercase, one uppercase, one number, and one special character')
            return
        } else if (password !== confirmPassword){
            setError('Passwords do not match')
            return
        }
        auth.confirmPasswordReset(oobCode,password)
            .then(()=>{
                setMessage('complete!')
            })
            .catch(err => setError(err))
    }

    if (mode === 'resetPassword' && oobCode){
        auth.verifyPasswordResetCode(oobCode)
        .then(function(resetEmail) {
            setEmail(resetEmail)
            setReset(true)
        })
        .catch(function() {
          // Invalid code
        })
    }
    if (reset){
        return (
            <div className="loginPage navTop">
                <div className='authForm'>
                    <form className='' onSubmit={handleReset}>
                        <h2 className='articleSubTitle authTitle'>Reset password</h2>
                        <label>
                            <span>email</span>
                            <input 
                            type="email"
                            disabled={true}
                            value={email}
                            onChange={() => setMessage(null) && setError(null)}
                            />
                        </label>
                        <label>
                            <span>new password</span>
                            <input 
                            type="password"
                            required
                            onChange={(e)=>setPassword(e.target.value)}
                            value={password}
                            minLength={8}
                            maxLength={40}
                            />
                        </label>
                        <label>
                            <span>confirm password</span>
                            <input 
                            type="password"
                            required
                            onChange={(e)=>setConfirmPassword(e.target.value)}
                            value={confirmPassword}
                            minLength={8}
                            maxLength={40}
                            />
                        </label>
                        {error && <Tag color='Danger' text={error}/>}
                        {message && <p className='articleText'>{message}</p>}
                        <button className="btn">Update</button>
                    </form>
                    <span className='authFooter'>
                            <Link to='/login' className='textBold'>login</Link>
                    </span>
                </div>
            </div>
        )
    }
    return (
        <div className="loginPage navTop">
            <div className='authForm'>
                <form className='' onSubmit={handleSubmit}>
                    <h2 className='articleSubTitle authTitle'>Reset password</h2>
                    <label>
                        <span>account email</span>
                        <input 
                        type="email"
                        required
                        onChange={(e) => {
                                setEmail(e.target.value)
                                setError(null)
                                setMessage(null)
                            }
                        }
                        value={email}
                        />
                    </label>
                    {message && <p className='articleText'>{message}</p>}
                    {error && <Tag color='Danger' text={error}/>}
                    <button className="btn">Send</button>
                </form>
                <span className='authFooter'>
                    <p>Have an existing account? </p>
                <Link to='/login'>login</Link>
            </span>
            </div>
        </div>
    )
}