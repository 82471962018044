import { useState } from 'react'
import { auth } from '../firebase/config'
import { useAuthContext } from './useAuthContext'
import { isStrongPwd } from '../util/Util'

export const useUpdate = () => {
  const [isCancelled, setIsCancelled] = useState(false)
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { dispatch, user } = useAuthContext()

  const update = async ({username, email, password}) => {
    if (user) {
      setError(null)
      setIsPending(true)
      try {
        // username
        let res = null
        if (username){
          res = await auth.currentUser.updateProfile({displayName: username})
        }
        // email
        if (email){
          res = await auth.currentUser.updateEmail(email)
        }
        //password
        if (password) {
          if (!isStrongPwd(password)) {
            throw new Error('Password must be 8-30 characters and must contain one lowercase, one uppercase, one number, and one special character')
          }

          res = await auth.currentUser.updatePassword(password)
        }
  
        if (res) {
          dispatch({ type: 'UPDATE', payload: res.user })
        }
  
        if (!isCancelled) {
          setIsPending(false)
          setError(null)
        }
      } 
      catch(err) {
        if (!isCancelled) {
          setError(err.message)
          setIsPending(false)
        }
      }
    }
  }

  return { update, isPending, error }
}