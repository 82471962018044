import './FeedNav.css'

export default function FeedNav({page, setPage, pageSize, feedLength, totalPages}) {
    const handleClick = (dir) => {
        if (dir === 'next') {
            setPage(prev => prev+1 <= totalPages ? prev + 1 : prev)
        } else if (dir ==='prev') {
            setPage(prev => prev > 0 ? prev - 1 : 0)
        }
    }

  return (
    <div className='feedNav articleText'>
        <span className='pageInfo'>
            {`[ ${feedLength===0 ? 0 : (page*pageSize)+1}-${Math.min(feedLength,(page*pageSize)+pageSize)} of ${feedLength} ]`}</span>
        <span onClick={()=>handleClick('prev')}
        className={`material-symbols-outlined pageNavIcon ${page===0 && 'inactiveIcon'}`}>navigate_before</span>
        <span className='pageNumber'>{page}</span>
        <span onClick={()=>handleClick('next')}
        className={`material-symbols-outlined pageNavIcon ${(page+1) * pageSize >= feedLength && 'inactiveIcon'}`}>navigate_next</span>
    </div>
  )
}