import './ExampleBox.css'
import { nanoid } from 'nanoid'
import { useState } from 'react'

export default function ExampleBox({examples,numbered, title, type, flatText="", copyButton=false}) {
  const [clicked, setClicked] = useState(false)

  const handleClick = () => {
    console.log(examples)
    navigator.clipboard.writeText(flatText)
    setClicked(true)
    return setTimeout(() => setClicked(false), 2000)
  }

  return (
    <div className={`exampleBox ${type || ''}`}>
        {title && (
          <div className='exampleHeader'>
            <code className='exampleBoxTitle'>
              {title}
            </code>
          </div>
        )}
        <div className='exampleBoxContent'>
           {copyButton && (
            <div className='simpleCopy' onClick={handleClick}>
              <span className={`material-symbols-outlined codeBoxCopyIcon ${clicked ? 'clicked' : 'notClicked'}`}>
              { clicked ? 'done' : 'content_copy'}
              </span>
            </div>
           )}
            <pre>
              {examples.map((text, i)=>{
                  return <div key={nanoid()} className='exampleBoxItem'>
                      {numbered && <code className='exampleBoxNumber'>{i}</code>}
                      <code className='exampleBoxText'>{text}</code>
                  </div>
              })}
            </pre>
        </div>
    </div>
  )
}