import './Iframe.css'
import { useSearchParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import NotFound from '../notFound/NotFound'
import Spinner from '../../components/generic/Spinner'
import { hydrateRouteDocData } from '../../util/Util'
import ApiDoc from '../../components/apidoc/ApiDoc'

export default function Iframe() {
  const [searchParams, setSearchParams] = useSearchParams()
  const key = searchParams.get('apiKey')
  const appId = searchParams.get('appId')
  const routeId = searchParams.get('routeId')
  const [routeData, setRouteData] = useState(null)

  useEffect(()=>{
    // /apps/<app>/routes/<route>
    const run = async () => {

      if (!(key && appId && routeId)) return

      const res = await fetch(
        `https://app.postget.dev/api/v1/owner/apps/${appId}/routes/${routeId}`,
        // 'https://postget.dev/api/v1/keys/reset',
        {
          method: 'GET',
          headers: {
              Authorization:`Basic ${key}`,
              // Origin: 'https://postget.dev',
              Origin: 'https://postget.dev',
              'Access-Control-Request-Method': 'GET',
              'Access-Control-Request-Headers': 'Content-Type'
          }
        }
      )
      try {
        const json = await res.json()
        if (json?.response?.exists){
          setRouteData(hydrateRouteDocData(json.response))
        }
      } catch (err) {
        return
      }
    }

    run()
    // const {documents: routeDocs} = useCollection(`users/${user?.uid}/apps/${appId}/routes`)
  },[key,appId,routeId])
  if (!(key && appId && routeId)) {
    return (
      <NotFound/>
    )
  }
  return (
    <div className="Iframe" aria-label='api documentation'>
      {routeData && (
          <ApiDoc
            dropSelect={<div className='muteSelect'></div>}
            apiData={routeData}
          />
        )
      }
      {!routeData && (
          <Spinner/>
        )
      }
    </div>
  )
}