import ApiDoc from "../../../components/apidoc/ApiDoc"
import { hydrateRouteDocData } from "../../../util/Util"
import PageNav from '../../../components/layout/PageNav';

const pageRoutes = {
  "routes":[
    {label:'- Routes Index',path:'/docs?view=routes#index'},
    {label:'- Routes Detail',path:'/docs?view=routes#detail'},
  ]
}
export default function RouteDoc() {

    const exampleObj = {
        'name':'Widget item route',
        'description':'A route for widgets',
        'methods':['GET','POST','PUT','DELETE'],
        'schema':{
            "type" : "object",
            "additionalProperties": false,
            "properties":{
                "name":{
                    "type":"string",
                    "minLength": 3,
                    "maxLength":50
                },
                "size":{
                    "type":"number",
                    "minimum":3,
                    "maximum":50
                }
            },
            "required":[
                    "name",
                    "size"
                ]  
        },
        "exampleObj": {"name":"widgetName","size":200},
        "defaultPermissions": ["read","update"],
        "flagOwner":false,
        'docId':'widget_route'
    }
    const schema = {
        "additionalProperties": false,
        "properties": {
          "name": {
            "maxLength": 20,
            "minLength": 3,
            "type": "string"
          },
          "description": {
            "maxLength": 500,
            "minLength": 3,
            "type": "string"
          },
          "methods": {
            "type": "array",
            "minItems": 1,
            "maxItems": 4,
            "items": {
                "type":"string",
                "enum": ["GET", "POST", "PUT", "DELETE"]
            }
          },
          "flagOwner": {
            "type": "boolean",
            "description": "Record <ownerId> on documents"
          },
          "schema": "<jsonSchema>",
          "exampleObj":"<object>",
          "defaultPermissions": {
            "type": "array",
            "items": {
                "type": "string",
                "enum": [
                  "read",
                  "write", 
                  "add",
                  "update",
                  "delete",
                  "readOwned", 
                  "updateOwned", 
                  "deleteOwned"
                ]
            }
          },
          "docId": {
            "maxLength": 45,
            "minLength": 3,
            "type": "string"
          }
        },
        "required": [
          "name",
          'description',
          "methods",
          "schema"
        ],
        "type": "object"
      }
    
    const indexUrl =  <span>https://app.postget.dev/api/v1/owner/apps/<span className='textBold'>{'<appId>'}</span>/routes</span>
    const detailUrl = <span>https://app.postget.dev/api/v1/owner/apps/<span className='textBold'>{'<appId>'}</span>/routes/<span className='textBold'>{'<docId>'}</span></span>
    const indexParams = [
        {
            type:'URL path',
            variables:[
              {name:'appId',required:true,desc:'The unique id of the parent app object'}
          ]
        }
    ]
    const detailParams = [
        {
          type:'URL path',
          variables:[
            {name:'docId',required:true,desc:'The unique id of the target route'},
            {name:'appId',required:true,desc:'The unique id of the parent app object'}
        ]
        }
      ]

    const apiData = {
        description:"A route is a live URL path which belongs to one app domain. Typically a route hosts an underlying collection of similar records. For this reason, routes are associated with schema. Routes can support many HTTP operations as well as complex queries.",
        docId:'widget_route',
        id:'routes',
        methods: ['GET','POST','PUT','DELETE'],
        name: 'Routes',
        links: [{to:'/docs?view=auth',desc:'Review authentication'},{to:'/docs?view=errors',desc:'Review error messages'}],
        schema,
        indexUrl,
        detailUrl,
        indexParams,
        detailParams,
        exampleObj
    }

    return (
        <div className='flexDiv apiDocContainer'>
          <div className="splitArticle apiDoc">
            <div className='articleHead'></div>
            <div className='articleBody'>
                <div className="articleCentered">
                    <ApiDoc apiData={hydrateRouteDocData(apiData, false)}/>
                </div>
            </div>
          </div>
          <PageNav routeGroups={pageRoutes}/>
        </div>
    )
  }