import { useEffect, useState } from "react"

export const usePubApp = ([appOwnerId, appId]) => {
    const [appData, setAppData] = useState({
                                        app:{},
                                        routes:[]
                                    })

    useEffect(()=>{

        const run = async () => {
            // try to fetch public app
            const res = await fetch(
                // `http://localhost:8080/api/v1/store/apps/${appId}?appOwner=${appOwnerId}`,
                `https://app.postget.dev/api/v1/store/apps/${appId}?appOwner=${appOwnerId}`,
                {
                    method: 'GET',
                    headers: {
                        Origin: 'https://postget.dev',
                        'Access-Control-Request-Method': 'GET'
                    }
                }
            )

            const json = await res.json()
            const appData = typeof json.response === "object" && json.response
            if (appData) {
                setAppData(appData)
            } else {
                setAppData({
                    app:{},
                    routes:[]
                })
            }
        }

        run()

    },[appId, appOwnerId])

    return {data:appData}
}