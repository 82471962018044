import ParamBox from "../apidoc/ParamBox"

export default function QueryParams({arg=false}) {

    const params = [
        {name:'limit',desc: 'An integer within range 0-500 to limit response payload'},
        {name:'start',desc: "A string recordId used to offset the returned response payload"},
        {name:'orderBy',desc: 'The attribute (string) to sort the response payload by'},
        {name:'orderDir',desc: "A string 'asc' or 'desc' specifying the sort order"},
        {name:'<arg>', desc: "Any root key contained in the schema"}
    ]
    
    return <ParamBox params={params} type={'URL query'}/>
}