import './Footer.css'
import { nanoid } from 'nanoid'
import { Link } from 'react-router-dom'

const dummyData ={
    Resources:[
      {label:'Documentation',to:'/docs?view=start'},
      {label:'Terms & conditions',to:'/terms'},
      {label:'Privacy policy',to:'/privacy'},
      {label:'Contact',to:'/contact'}],
    'Get Started':[{label:'Login',to:'/login'},{label:'Signup',to:'/signup'}],
    
}
export default function Footer({routeMaps}) {
  return (
    <div className='footer'>
      <div className='footerLogo'>
        <h6 className='footerTitle'>{'< postget />'}</h6>
      </div>
      <div className='footerLinks'>
        {Object.keys(dummyData).map((title)=>{
          return <div key={nanoid()} className='footerLinkCol'>
            <h6 className='footerTitle'>{title}</h6>
              <ul>
                {dummyData[title].map((row)=>{
                  return <li className='footerLink' key={nanoid()}><Link to={row.to}>{row.label}</Link></li>
                })}
              </ul>
          </div>
        })}
      </div>
      <div className='footerFooter'></div>
    </div>
  )
}