const customStyle =  {
    "hljs-comment": {
        "color": "var(--term-grey)"
    },
    "hljs-quote": {
        "color": "var(--term-carrot)"
    },
    "hljs-variable": {
        "color": "var(--term-carrot)"
    },
    "hljs-template-variable": {
        "color": "var(--term-carrot)"
    },
    "hljs-tag": {
        "color":  "var(--term-white)"
    },
    "hljs-name": {
        "color": "var(--term-carrot)"
    },
    "hljs-selector-id": {
        "color": "var(--term-carrot)"
    },
    "hljs-selector-class": {
        "color": "var(--term-carrot)"
    },
    "hljs-regexp": {
        "color": "var(--term-carrot)"
    },
    "hljs-deletion": {
        "color": "var(--term-jelly)"
    },
    "hljs-number": {
        "color": "var(--term-jelly)"
    },
    "hljs-built_in": {
        "color": "var(--term-carrot)"
    },
    "hljs-builtin-name": {
        "color": "var(--term-royal)"
    },
    "hljs-literal": {
        "color": "var(--term-jelly)"
    },
    "hljs-type": {
        "color": "var(--term-carrot)"
    },
    "hljs-params": {
        "color":  "var(--term-carrot)"
    },
    "hljs-meta": {
        "color": "var(--term-carrot)"
    },
    "hljs-link": {
        "color": "var(--term-carrot)"
    },
    "hljs-attribute": {
        "color": "var(--term-carrot)"
    },
    "hljs-string": {
        "color": "var(--term-ivy)"
    },
    "hljs-symbol": {
        "color": "var(--term-white)"
    },
    "hljs-bullet": {
        "color": "green"
    },
    "hljs-addition": {
        "color": "green"
    },
    "hljs-title": {
        "color": "green"
    },
    "hljs-section": {
        "color": "green"
    },
    "hljs-keyword": {
        "color": "var(--term-carrot)"
    },
    "hljs-selector-tag": {
        "color": "green"
    },
    "hljs": {
        "display": "block",
        "overflowX": "auto",
        "background": "#1d1f21",
        "color": "rgb(210, 210, 210)",
        "padding": "0.5em"
    },
    "hljs-emphasis": {
        "fontStyle": "italic"
    },
    "hljs-strong": {
        "fontWeight": "bold"
    }
}
export default customStyle