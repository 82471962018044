import { Link } from "react-router-dom";
import InfoBox from "../../../components/extra/InfoBox";

export default function StartDoc() {
    return (
      <div className="Article">
        <div className="articleCentered">
          <h3 className="articleTitle">Getting Started</h3>
          <p className="articleText">
          👋 Welcome to postget docs! <span className="textBold">postget.dev</span> is a platform that makes building backend applications easy.
          </p>
          <InfoBox color='Primary' text={<Link to='/docs?view=release' className="appLink">{'Current version: 0.1.1 (ALPHA)'}</Link>} icon='star'/>
          <p className="articleText">
            If you're reading these docs, you're probably an application owner looking to <span className="textBold">build, deploy, document, monitor, and maybe even monetize</span> a REST API application as quickly and painlessly as possible...
          </p>
          <p className="articleText">
            That's great! We can certainly help with that. Let's get started.
            When you gain access to your "owner tier" <Link to='/apikeys?view=secret' className="appLink textBold">API key</Link>, you'll immediately have access to create the following live resources programmatically:
          </p>
          <ul className="articleUl">
            <li id="apps">
              <h3 className="articleSubTitle flexTitle"><span className="material-symbols-outlined icon">widgets</span>Apps</h3>
              <p className="articleText">
                An app represents a domain of routes, clients, permissions, statistics, and other features. You 
                may choose to deploy one app for each organization you manage.
                An app typically hosts many routes and users.
              </p>
              <Link to='/docs?view=apps' className="appLink textBold startLink"> Learn more about the apps resource</Link> 
            </li>
            <li id="routes">
              <h3 className="articleSubTitle flexTitle"><span className="material-symbols-outlined icon">alt_route</span>Routes</h3>
              <p className="articleText">A route is a live URL path belonging to one app domain. If for example you are building 
              an online store, you may decide to deploy one route to host inventory records and another route to host order records.</p>
              <p className="articleText">Typically a route hosts an underlying collection of similar records... 
              for this reason, routes are associated with schema. Routes endpoints can support many <code className="inlineCode textBold">HTTP</code> operations <code className="inlineCode textBold">(GET, POST, PUT...)</code> as well as complex queries</p>
              {/* <InfoBox text={'All routes are live on creation'} color='Yellow' icon='bulb'/> */}
              <Link to='/docs?view=routes' className="appLink textBold startLink">Learn more about the routes resource</Link> 
            </li>
            <li id="clients">
              <h3 className="articleSubTitle flexTitle"><span className="material-symbols-outlined icon">person</span>Clients</h3>
              <p className="articleText">A client is a user or service account who may be granted "consumer tier" access to a specific app.
              If for example you've built an app for your online store with a route to host inventory records, you may decide to create a client account for one of your vendors for the purpose of automating recurring orders.</p>
              <p className="articleText">A client performs <code className="inlineCode textBold">HTTP</code> operations and queries against your app routes, is associated with <span className="textBold">one</span> app domain, and is configured with a set of permissions</p>
              <Link to='/docs?view=clients' className="appLink textBold startLink">Learn more about the client resource</Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }