import './Signup.css'
import AuthForm from '../../components/forms/AuthForm';

export default function Signup() {
    return (
      <div className="loginPage navTop">
        {/* <h2 className="articleSubTitle">Login</h2> */}
        <AuthForm 
        title='Create an account' 
        buttonText='Signup' 
        confirm={{email:true}}
        footer={{text:'Already have an account?',route:'/login'}}
        />
      </div>
    );
  }