import './CodeExample.css'
import { nanoid } from 'nanoid'

export default function CodeExample({lines}) {

  return (
    <div className='codeExample'>
      {lines.map((line)=>{
        return <code key={nanoid()}>{line}</code>
      })}
    </div>
  )
}