import './CodeBox.css'
// import SyntaxHighlighter from 'react-syntax-highlighter';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import js from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import py from 'react-syntax-highlighter/dist/esm/languages/hljs/python';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
import html from 'react-syntax-highlighter/dist/esm/languages/hljs/htmlbars';
import customStyle from './CodeTheme';
import { useState } from 'react';

SyntaxHighlighter.registerLanguage('javascript', js)
SyntaxHighlighter.registerLanguage('python', py)
SyntaxHighlighter.registerLanguage('json', json)
SyntaxHighlighter.registerLanguage('html', html)
// import {hybrid as customStyle} from 'react-syntax-highlighter/dist/esm/styles/hljs'
// import {atelierSavannaDark as customStyle} from 'react-syntax-highlighter/dist/esm/styles/hljs'
// import {vscDarkPlus} from 'react-syntax-highlighter/dist/esm/styles/prism'
// tomorrowNight lioshi hybrid atelierSavannaDark  railscasts darkula

export default function CodeBox({codeText, codeTitle, headerView, lines=false, language='json', wrap=false}) {
  const termStyle= {color:'var(--term-white)', backgroundColor:'var(--term-bg)',marginTop:'0px', padding: '20px', paddingLeft:lines ? 0 : 20, fontSize:'14px',borderBottomLeftRadius:'3px',borderBottomRightRadius:'3px'}
  if (!headerView){
    termStyle.borderTopLeftRadius='3px'
    termStyle.borderTopRightRadius='3px'
  }

  const [clicked, setClicked] = useState(false)

  const handleClick = () => {
    navigator.clipboard.writeText(codeText)
    setClicked(true)
    return setTimeout(() => setClicked(false), 2000)
  }

  
  return (
    <div className={'codeBox'}>
        {headerView && (
          <div className='codeHeader'>
          <div className='codeBoxTitle'>
            {codeTitle}
          </div>
          <div className='codeBoxCopy' onClick={handleClick}>
            <span className="material-symbols-outlined codeBoxCopyIcon notClicked">
              content_copy
            </span>
            <span className='codeBoxCopyText'>
              {clicked ? 'Done' : 'Copy'}
            </span>
          </div>
        </div>
        )}
        {!headerView && (
          <div className='simpleCopy' onClick={handleClick}>
            <span className={`material-symbols-outlined codeBoxCopyIcon ${clicked ? 'clicked' : 'notClicked'}`}>
            { clicked ? 'done' : 'content_copy'}
            </span>
          </div>
        )}
        <div className='wrapBox'>
          <SyntaxHighlighter 
              showLineNumbers={lines} 
              language={language} 
              style={customStyle}
              lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
              wrapLines={true} 
              customStyle={termStyle}>
              {codeText}
          </SyntaxHighlighter>
        </div>
        <div className='extendBox'>
          <SyntaxHighlighter 
              showLineNumbers={lines} 
              language={language} 
              style={customStyle}
              customStyle={termStyle}>
              {codeText}
          </SyntaxHighlighter>
        </div>
    </div>
  )
}