import CodeBox from "../../../components/extra/CodeBox";
import ArticleSection from "../../../components/layout/ArticleSection";
import CodeExample from "../../../components/extra/CodeExample";
import { Link } from "react-router-dom";
import InfoBox from "../../../components/extra/InfoBox";

export default function AuthDoc() {
    const authHeaderEx = {Authorization:'Basic MsvOiG5znweHA0EcSaLp'}
    return (
        <div className="Article apiDoc">
            <div className="articleCentered">
              <h3 className="articleTitle">Authentication</h3>
              <p className="articleText">
                Your API key is used to associate your requests with your account and grants root level access to
                <span className="textBold">{' all'}</span> of your applications and sub domains.
                <span className="textBold"> Do not expose or share your key with anyone. </span>
                If you ever suspect your key has been compromised, use the refresh operation on the key page
                to retire your key and generate a new one.
              </p>
              <h3 className="articleSubTitle flexTitle" id="requestheaders" ><span className="material-symbols-outlined icon">key</span>Request headers</h3>
                <p className='articleText'>
                  When accessing the owner API, you will authenticate by including your API key in the <code className="inlineCode textBold">{' Authorization'}</code> header as shown in the below example... (the term <code className="inlineCode">Basic</code> followed by your unique key). <Link className='appLink textBold' to='/apikeys?view=secret'>Access key related operations</Link>
                </p>
              <CodeBox lines={true} codeText={JSON.stringify(authHeaderEx,null,4)} codeTitle='Auth headers' headerView={false}/>              
              <h3 className="flexTitle articleSubTitle" id="responseheaders"><span className="material-symbols-outlined icon">terminal</span>Response headers</h3>
                <p className='articleText'>
                  A <code className="inlineCode">50,000</code> daily limit is enforced on your key. Once the <code className="inlineCode">50,000</code> limit has been 
                  reached on a given day, subsequent queries will fail.
                </p>
                <InfoBox color='Yellow' text={"At this time there is also a low level burst limit that may drop your traffic if you sustain an average of 3+ requests per second over a 60 second rolling window"}/>
                <p className='articleText'>
                  The <code className="inlineCode">X-RateLimit-Limit</code>, <code className="inlineCode">X-RateLimit-Remaining</code>, and <code className="inlineCode">X-RateLimit-Reset</code> headers will be set on API replies to help you track your usage and remaining resources
                </p>
            </div>
      </div>
    );
  }