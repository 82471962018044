import './AppGallery.css'
import { nanoid } from 'nanoid'
import { Link, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import Modal from '../../components/generic/Modal';
import FeedNav from '../extra/FeedNav';
import CopyButton from '../extra/copyButton';
import { useAuthContext } from '../../hooks/useAuthContext'

export default function AppGallery({appData, handleReset, handleSubscribe}) {

    const {user, authIsReady} = useAuthContext()
    const [page, setPage] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const pageSize = 50
    const navigate = useNavigate();

    useEffect(()=>{
        setTotalPages(Object.keys(appData).length/pageSize)
    },[appData,pageSize])
    
    const [showKey, setShowKey] = useState(false)
    const [search, setSearch] = useState("")
    const [enforceFilter, setEnforceFilter] = useState(false)
    const [showModal, setShowModal] = useState({})

    const handleChange = (e) => {
        e.preventDefault()
        setSearch(e.target.value)
    }

    const handleShowKey = (key) => {
        setShowKey(prev => {
            if (prev === key) {
                return false
            } else {
                return key
            }
        })
    }

    const confirmReset = (key, appPath) => {
        handleReset(key, appPath)
    }
    const confirmSubscribe = (appPath) => {
        handleSubscribe(appPath)
    }

    const handleKeyUp = (e) => {
        e.preventDefault()
        if (e.key === 'Enter') {
            handleFilter()
        }
    }

    const handleFilter = () => {
        if (search === "") {
            setEnforceFilter(false)
        } else {
            setEnforceFilter(search)
        }
    }

    return (
        <>
            {Object.keys(showModal)[0] === "reset" && <Modal 
                setShowModal={()=>setShowModal({})} 
                onConfirm={()=>{confirmReset(showModal.reset.key, showModal.reset.appPath)}}
                header={`Recycle ${showModal.reset.name} key`}
                route="/appstore"
                message={
                <div className='padModal'>Delete your old key and generate a new one? <span className="textBold">This action is NOT reversible and may take several seconds to propagate</span></div>}
                />
            }
            {Object.keys(showModal)[0] === "subscribe" && <Modal 
                setShowModal={()=>setShowModal({})} 
                onConfirm={()=>{confirmSubscribe(showModal.subscribe.appPath)}}
                header={`Subscribe to ${showModal.subscribe.name}`}
                route="/appstore"
                tone="Primary"
                message={
                <div className='padModal'>Generate an alias key to use this app?</div>}
                />
            }

            <div className="galleryContainer">
                {/* 
                    reset and subscribe modals
                */}
                <div className='gallaryForm'>
                    <div className='formButtons'>
                            <Link to="/docs?view=start" className=" icon">
                                info
                            </Link>
                            <Link to="/apikeys?view=secret" className="icon createIcon">
                                create
                            </Link>
                    </div>
                    <div className='gallarySearch articleText'>
                        <span 
                        className="material-symbols-outlined icon"
                        onClick={()=>setSearch("")}>
                            close
                        </span>
                        <input
                            type="text"
                            placeholder="Search"
                            onKeyUp={handleKeyUp}
                            onChange={handleChange}
                            value={search} />
                        <span className="material-symbols-outlined icon" onClick={handleFilter}>
                            search
                        </span>
                    </div>
                </div>
                <div className='articleText'>
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    App
                                </th>
                                <th className='authorCol'>
                                    Author
                                </th>
                                <th>
                                    About
                                </th>
                                <th>
                                    Key
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(appData).map((appPath) => {
                                if (enforceFilter && 
                                    (!appData[appPath].name.toLowerCase().includes(enforceFilter.toLowerCase()) 
                                    && !(appData[appPath].tags || []).map((x)=>x.toLowerCase()).includes(enforceFilter.toLowerCase()))) {
                                        return
                                }
                                return (
                                    <tr key={nanoid()} onClick={()=>navigate(`/appstore/${appPath}?view=overview`)}>
                                        <td className='appNameTd'>
                                            <p className='appName'>{appData[appPath].name}</p>
                                        </td>
                                        <td className='appAuthor authorCol'>
                                            <span>{appData[appPath].author.charAt(0).toUpperCase()+appData[appPath].author.slice(1,)}</span>
                                        </td>
                                        <td className='keyColumn'>
                                            <div className='appDesc'><em>"{appData[appPath].description.slice(0,Math.min(appData[appPath].description.length,60))}..."</em>
                                            </div>

                                        </td>
                                        <td>
                                            {
                                                appData[appPath].alias?.apiClient?.apiKey
                                                ?  <div className='tabKey secretBlock'>
                                                    {showKey === appData[appPath].alias.apiClient.apiKey ? appData[appPath].alias.apiClient.apiKey : "***************"}
                                                </div>
                                                :  <span 
                                                className='subLink appLink apiLink'
                                                onClick={(e)=>{
                                                    e.stopPropagation()
                                                    if (!user) {
                                                        navigate("/login")
                                                    } else {
                                                        setShowModal({
                                                            subscribe:{appPath:appPath,
                                                            name:appData[appPath].name
                                                        }})
                                                    }
                                                }}
                                                >
                                                    {"< subscribe! >"}
                                                </span>
                                            }
                                        </td>
                                        <td>
                                            {
                                                appData[appPath].alias?.apiClient?.apiKey 
                                                ? (
                                                    <>
                                                        <CopyButton 
                                                        className={'apiLink apiLinkCopy'}
                                                        text={appData[appPath].alias.apiClient.apiKey || "null"}/>
                                                        <span 
                                                        className="material-symbols-outlined visibilityIcon apiLink"
                                                        onClick={(e)=>{
                                                            e.stopPropagation()
                                                            handleShowKey(appData[appPath].alias.apiClient.apiKey )
                                                        }}
                                                        >
                                                            {showKey === appData[appPath].alias?.apiClient?.apiKey  ? "visibility_off" : "visibility"}
                                                        </span>
                                                        <span 
                                                        className="material-symbols-outlined apiLink" 
                                                        onClick={(e)=>{
                                                            e.stopPropagation()
                                                            setShowModal({
                                                                reset:{appPath:appPath,
                                                                key:appData[appPath].alias.apiClient.apiKey,
                                                                name:appData[appPath].name
                                                            }})
                                                        }}
                                                        >
                                                            sync
                                                        </span>
                                                    </>
                                                )
                                                : <></>
                                            }

                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <FeedNav page={page} setPage={setPage} pageSize={pageSize} feedLength={Object.keys(appData).length} totalPages={totalPages}/>
                </div>
            </div>
        </>

    )
}