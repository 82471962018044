import { useRoadmap } from '../../../hooks/useRoadmap'
import { nanoid } from 'nanoid'

export default function RoadmapDoc() {
    const {roadmapDoc} = useRoadmap()
    return (<div className="roadmap Article">
        <div className="articleCentered">
            <h3 className="articleTitle">Roadmap</h3>
            <p className='articleText'><em>This is the postget road map page! Check in here to find out about
                what we're currently working on</em></p>
            <ul className='releaseList'>
                {
                    roadmapDoc?.features.map((feature)=>{
                        return (
                            <li key={nanoid()}>
                                <h3 className='articleSubTitle '>
                                    <span className="material-symbols-outlined center icon primaryIcon">build_circle</span>
                                    <span className='center'>{feature.title}</span>
                                </h3>
                                <p className='articleText'>{feature.description}</p>
                                <ul className='articleText subul'>
                                    <li>
                                        <span className='textBold'>ETA: </span>
                                            {feature.ETA}   
                                    </li>
                                    <li>
                                    <span className='textBold'>Priority: </span>
                                    {feature.priority} 
                                    </li>
                                </ul>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    </div>)
}