import { useSearchParams } from 'react-router-dom';
import './Documentation.css'
import SideNav from '../../components/layout/SideNav';
import PageNav from '../../components/layout/PageNav';
import AppsDoc from './subpages/Apps';
import AuthDoc from './subpages/Auth';
import StartDoc from './subpages/Start';
import RouteDoc from './subpages/Route';
import ClientDoc from './subpages/Client';
import ErrorDoc from './subpages/Errors';
import SpecialDoc from './subpages/Special';
import SerializationDoc from './subpages/Serialization';
import ClientOverDoc from './subpages/ClientOverview';
import ClientQueryDoc from './subpages/ClientQuery';
import ReleaseDoc from './subpages/Release';
import HowToDoc from './subpages/HowTo';
import RoadmapDoc from './subpages/Roadmap';
import DocEx from './subpages/DocEx'
import PageDoc from './subpages/Pagination';
import Permissions from './subpages/Permissions';
import Appstore from './subpages/Appstore';
//  side routes: getting started, serialization, reserved keys, examples, 

const routeGroups = {
  'Owner API':[
    {label:'Getting started',value:'start',path:'/docs?view=start'},
    {label:'Authentication',value:'auth',path:'/docs?view=auth'},
    {label:'Apps',value:'apps',path:'/docs?view=apps'},
    {label:'Routes',value:'routes',path:'/docs?view=routes'},
    {label:'Clients',value:'clients',path:'/docs?view=clients'},
    {label:'App store',value:'appstore',path:'/docs?view=appstore'}],
  'Conventions':[
    {label:'Query & serialization',value:'serialization',path:'/docs?view=serialization'},
    {label:'Special cases',value:'specialcases',path:'/docs?view=specialcases'},
    {label:'Error archive',value:'errors',path:'/docs?view=errors'},
    {label:'Pagination',value:'pagination',path:'/docs?view=pagination'}
  ],
  'Client API':[
    {label:'Overview',value:'overview',path:'/docs?view=overview'},
    {label:'Complex queries',value:'query',path:'/docs?view=query'},
    {label:'Doc export',value:'docex',path:'/docs?view=docex'},
    {label:'Permissions',value:'permissions',path:'/docs?view=permissions'}
  ],
  'General Info':[
    {label:'Release history',value:'release',path:'/docs?view=release'},
    {label:'Roadmap',value:'roadmap',path:'/docs?view=roadmap'},
    {label:'Example',value:'example',path:'/docs?view=example'},
  ]
}

const pageRoutes = {
  "start":[
    {label:'- Apps',path:'/docs?view=start#apps'},
    {label:'- Routes',path:'/docs?view=start#routes'},
    {label:'- Clients',path:'/docs?view=start#clients'},
  ],
  "auth":[
    {label:'- Request headers',path:'/docs?view=auth#requestheaders'},
    {label:'- Response headers',path:'/docs?view=auth#responseheaders'},
  ],
  "apps":[],
  "routes":[],
  "clients":[],
  "serialization":[
    {label:'- Payload/JSON Serialization',path:'/docs?view=serialization#payloadser'},
    {label:'- URL Query Serialization',path:'/docs?view=serialization#urlser'},
  ],
  "specialcases":[
    {label:'- Reserved',path:'/docs?view=specialcases#reserved'},
    {label:'- Nested Updates',path:'/docs?view=specialcases#nested'},
    {label:'- Field Deletions',path:'/docs?view=specialcases#delete'},
    {label:'- Changing Route Schema',path:'/docs?view=specialcases#change'},

  ],
  "errors":[],
  "pagination":[
    {label:'- Example',path:'/docs?view=pagination#example'},
  ],
  "overview":[
    {label:'- Access key',path:'/docs?view=overview#access'},
    {label:'- Reset key',path:'/docs?view=overview#reset'},
    {label:'- Documentation',path:'/docs?view=overview#document'},
    {label:'- Routes',path:'/docs?view=overview#route'},
    {label:'- Index',path:'/docs?view=overview#index'},
    {label:'- Detail',path:'/docs?view=overview#detail'},

  ],
  "query":[
    {label:'- Query parameters',path:'/docs?view=query#query'},
  ],
  "docex":[
    {label:'- iframe',path:'/docs?view=docex#iframe'},
    {label:'- OpenApi',path:'/docs?view=docex#openapi'},
  ],
  "release":[],
  "roadmap":[],
  "example":[
    {label:'- The use case',path:'/docs?view=example#usecase'},
    {label:'- Getting started',path:'/docs?view=example#start'},
    {label:'- Building the app',path:'/docs?view=example#app'},
    {label:'- Building the first route',path:'/docs?view=example#firstroute'},
    {label:'- Building the second route',path:'/docs?view=example#secondroute'},
    {label:'- Adding our users',path:'/docs?view=example#users'},
    {label:'- Adding a read only account',path:'/docs?view=example#readonly'},
    {label:'- Accessing the keys',path:'/docs?view=example#keys'},
    {label:'- Integrations',path:'/docs?view=example#integrate'},
    {label:'- Verification',path:'/docs?view=example#verify'},
  ],
  "permissions":[
    {label:'- Tags',path:'/docs?view=permissions#tags'},
    {label:'- Route level',path:'/docs?view=permissions#routelevel'},
    {label:'- User level',path:'/docs?view=permissions#userlevel'},
    {label:'- Order of ops',path:'/docs?view=permissions#orderofops'},
  ],
  "appstore":[
    {label:'- Publish',path:'/docs?view=appstore#publish'},
    {label:'- Consume',path:'/docs?view=appstore#consume'}
  ]
}

export default function Documentation() {
    const [searchParams, setSearchParams] = useSearchParams();
    const view = searchParams.get('view')
    return (
      <>
        <SideNav routeGroups={routeGroups}/>
        <div className={["apps","routes","clients"].includes(view) ? "apiDocumentation" : "Documentation"}>
            {view === 'start' && (
              <StartDoc/>
            )}
            {view === 'auth' && (
              <AuthDoc/>
            )}
            {view === 'apps' && (
              <AppsDoc/>
            )}
            {view === 'routes' && (
              <RouteDoc/>
            )}
            {view === 'clients' && (
              <ClientDoc/>
            )}
            {view === 'errors' && (
              <ErrorDoc/>
            )}
            {view === 'specialcases' && (
              <SpecialDoc/>
            ) }
            {view === 'serialization' && (
              <SerializationDoc/>
            )}
            {view === 'overview' && (
              <ClientOverDoc/>
            )}
            {view === 'query' && (
              <ClientQueryDoc/>
            )}
            {view === 'release' && (
              <ReleaseDoc/>
            )}
            {view === 'roadmap' && (
              <RoadmapDoc/>
            )}
            {view === 'example' && (
              <HowToDoc/>
            )}
            {view === 'docex' && (
              <DocEx/>
            )}
            {view === 'pagination' && (
              <PageDoc/>
            )}
            {view === 'permissions' && (
              <Permissions/>
            )}
            {view === 'appstore' && (
              <Appstore/>
            )}
          {pageRoutes[view].length > 0 && <PageNav routeGroups={pageRoutes}/>}
        </div>

      </>
    );
  }