import { Link } from "react-router-dom";
import CodeBox from "../../../components/extra/CodeBox";
import InfoBox from "../../../components/extra/InfoBox";
import CodeExample from "../../../components/extra/CodeExample";
import ParamBox from "../../../components/apidoc/ParamBox";
import QueryParams from "../../../components/extra/QueryParams";

export default function ClientOverDoc() {
    return (
        <div className="Article apiDoc">
            <div className="articleCentered">
                <h3 className="articleTitle">Client Overview</h3>
                <p className="articleText">
                The client API resource is the consumer facing API you will grant your users access to.
                As you create applications, routes, and users on the owner API side, those resources
                will become available on the platform for consumption. If you have not yet built any apps/routes or set up any clients, <Link className="appLink textBold" to='/docs?view=start'>get started here.</Link>
                </p>
                <p className="articleText">
                    <Link to='/docs?view=auth' className='appLink textBold'>
                        [Review authentication]
                    </Link>
                </p>
                <h3 className="articleSubTitle flexTitle" id="access"><span className="material-symbols-outlined icon">door_open</span>Access key</h3>
                <p className="articleText">
                    Access and distribute your client's keys via:
                </p>
                <CodeExample lines={[<span>curl https://app.postget.dev/api/v1/keys/<span className="textBold">{'<client>'}</span></span>]}/>
                <ParamBox params={[{name:'client',desc:'The client id for which you want to retrieve a secret key',required:true}]} type='Url path'/>
                <ParamBox params={[{name:'appId',desc:'The docId of the app which hosts the target client. This is only required when accessing this resource from an owner key since an owner can have multiple apps.',required:false}]} type='Url query'/>
                <CodeBox headerView={false} codeText={JSON.stringify({response:{apiKey:"MsvOiG5znweHA0EcSaLp"}},null,4)}/>

                <h3 className="articleSubTitle flexTitle" id="reset"><span className="material-symbols-outlined icon">sync</span>Reset key</h3>
                <p className="articleText textBold">
                    All accounts (owner level and client level) can reset their keys via:
                </p>
                <CodeExample lines={[<span>POST https://app.postget.dev/api/v1/keys/reset</span>]}/>
                <CodeBox headerView={false} codeText={JSON.stringify({response:{apiKey:"MsvOiG5znweHA0EcSaLp"}},null,4)}/>
                <InfoBox 
                text={"The account associated with the key in the auth header is the account which will have its key reset"}
                color='Yellow'
                icon='bulb'
                />               

                <h3 className="articleSubTitle flexTitle" id="document"><span className="material-symbols-outlined icon">summarize</span>Documentation</h3>
                <p className="articleText">
                    As you build your apps, custom API documentation will automatically populate <Link className="appLink textBold" to='/apps?view=routes'>on your apps page</Link>. Feel free to distribute your automated API documentation to clients and stakeholders.
                </p>

                <h3 className="articleSubTitle flexTitle" id="route"><span className="material-symbols-outlined icon">alt_route</span>Routes</h3>
                <p className="articleText">
                What follows is a high level overview of the client resource mechanics.
                To see verbose custom documentation about your specific routes, schema, and resources, use the <Link className="appLink textBold" to='/apps?view=routes'>apps page.</Link> Note that <span className="textBold">you have direct access to client level resources</span> with your owner level account/key by default</p>
                <h3 className="articleSubTitle flexTitle routeTitle" id="index"><span className="material-symbols-outlined icon">bolt</span>Index</h3>
                <p className="articleText">
                Each route you've created has an index resource (aka a list view). When a user wants to query the index of a specific route, 
                they can access the following endpoint:
                </p>
                <CodeExample lines={[<span>GET/POST https://app.postget.dev/api/v1/client/<span className="textBold">{'<routeId>'}</span></span>]}/>
                <ParamBox type="Url path" params={[{name:'routeId',desc:"The docId of the custom route object you've built",required:true}]}/>
                <div className="margBottom">
                    <QueryParams/>
                </div>
                <InfoBox 
                text={"If you are accessing via an owner account, you must also include 'appId' as a query parameter to specify which app is in focus"}
                color='Yellow'
                icon='bulb'
                />
                <p className="articleText textBold">
                    Methods (if enabled by owner on the route): <span className="successText">GET</span> <span className="primaryText">POST</span>
                </p>
                <CodeBox headerView={false} codeText={JSON.stringify({response:{}},null,4)}/>

                <h3 className="articleSubTitle flexTitle routeTitle" id="detail"><span className="material-symbols-outlined icon">bolt</span>Detail</h3>
                <p className="articleText">
                Each route you've created has a detail resource to access individual records. When a user wants to query a specific record instance, they will access the following endpoint:
                </p>
                <CodeExample lines={[<span>https://app.postget.dev/api/v1/client/<span className="textBold">{'<routeId>'}</span>/<span className="textBold">{'<docId>'}</span></span>]}/>
                <ParamBox type="Url path" params={[{name:'routeId',required:true,desc:"The docId of the custom route object you've built"},{name:'docId',required:true,desc:'The unique id of the existing record to be read, updated, or deleted'}]}/>
                <ParamBox type="Url query" params={[{name:'appId',required:false,desc:'The id of the parent app domain which hosts the target record. This argument is only required when accessing from an owner key since an owner can have multiple apps'}]}/>
                <p className="articleText textBold">
                    Methods (if enabled by owner on the route): <span className="successText">GET</span> <span className="warningText">PUT</span>  <span className="dangerText">DELETE</span>
                </p>
                <CodeBox headerView={false} codeText={JSON.stringify({response:{}},null,4)}/>
            </div>
      </div>
    )
}