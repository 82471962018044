
export default function Permissions() {
        // option overview, order of evaluation...
    return (
        <div className='Article articleText'>
          <div className="articleCentered">
            <h3 className="articleTitle">Permissions</h3>
            <p>A critical requirement for building interesting and complex apps is handling nuanced and layered permissions. If you need to build a simple data repository, you can get make due with read/write level access control... but if you want to build something like a public forum or a general ledger, you'll soon need a way to handle more complex relational access restrictions. Let's go over the different permission tags and layers available to you on <span className="textBold">postget.dev</span>.</p>
            <h3 id="tags" className="articleSubTitle flexTitle"><span class="material-symbols-outlined icon">label</span>Tags</h3>
            <p>At this time, <span className="textBold">postget.dev</span> supports eight unique permission tags that can be granted on either the route or the client level. What follows is an overview of each permission tag and its effects.</p>
            
            <p><span className="textBold">read:</span> The <code className="inlineCode">read</code> permission allows the user to perform <code className="inlineCode">GET</code> operations both against an index/list view as well as against any target document.</p>
            <p><span className="textBold">write:</span> The <code className="inlineCode">write</code> permission is a high level aggregation of the <code className="inlineCode">add</code>, <code className="inlineCode">update</code>, and <code className="inlineCode">delete</code> permissions. A user with <code className="inlineCode">write</code> permissions is able to execute <code className="inlineCode">POST</code>, <code className="inlineCode">PUT</code>, and <code className="inlineCode">DELETE</code> operations against indexes and individual documents.</p>

            <p><span className="textBold">add:</span> The <code className="inlineCode">add</code> permission is more specific. A user with <code className="inlineCode">add</code> access is able to issue a <code className="inlineCode">POST</code> operation against an index resource to create new documents. If a user with <code className="inlineCode">add</code> permissions attempts to issue a <code className="inlineCode">POST</code> against an existing record without <code className="inlineCode">write</code> or <code className="inlineCode">update</code> permissions, the user will be denied.</p>
            <p><span className="textBold">update:</span> The <code className="inlineCode">update</code> permission allows the user to execute <code className="inlineCode">POST</code> and <code className="inlineCode">PUT</code> operations so long as the target of the operation already exists.</p>
            <p><span className="textBold">delete:</span> A user with <code className="inlineCode">delete</code> permissions is able to execute the <code className="inlineCode">DELETE</code> operation against any existing record hosted by the route.</p>
            <p><span className="textBold">readOwned:</span> A user with <code className="inlineCode">readOwned</code> permissions is able to execute all valid <code className="inlineCode">read</code> operations on documents they've been recorded as an owner of.</p>
            <p><span className="textBold">updateOwned:</span> A user with <code className="inlineCode">updateOwned</code> permissions is able to execute all valid <code className="inlineCode">update</code> operations on documents they've been recorded as an owner of.</p>
            <p><span className="textBold">deleteOwned:</span> A user with <code className="inlineCode">deleteOwned</code> permissions is able to execute all valid <code className="inlineCode">delete</code> operations on documents they've been recorded as an owner of.</p>
            <h3 id="routelevel" className="articleSubTitle flexTitle"><span class="material-symbols-outlined icon">alt_route</span>Route level</h3>
            <p>Permissions can either be set on the route via the <code className="inlineCode">defaultPermissions</code> array or on the client via the <code className="inlineCode">permissions</code> map. If you review the routes/clients api docs schema section, you can get a better idea of the exact schema. Think of route permissions as a safety net or generalization. A default permission on a route will grant access to every app client. This is especially useful for apps with a large number of users that require similar permissions such as public apps.</p>
            <h3 id="userlevel" className="articleSubTitle flexTitle"><span class="material-symbols-outlined icon">person</span>User level</h3>
            <p>You may find that while some permissions can be generalized to the route level, other permissions need to be handled on a per user basis... for example, you may grant all users of an app <code className="inlineCode">read</code> permissions on the route level but only grant administrators <code className="inlineCode">write</code> permissions on the user level. Take advantage of user level permission maps to achieve this.</p>
            <h3 id="orderofops"className="articleSubTitle flexTitle"><span class="material-symbols-outlined icon">gavel</span>Order of operations</h3>
            <p>It's important to be explicit about exactly how permissions are evaluated. For starters, please note that <span className="textBold">all permissions are positive...</span> that is to say, it's only possible to expressly grant access. <span className="textBold">By default all actions are denied</span>. If you do not want a user to have <code className="inlineCode">read</code> access, make sure you do not grant them <code className="inlineCode">read</code> access... all this to say, there are no denyRead or denyUpdate permission types. When a user attempts to perform an operation against a specific route resource, this is how access is evaluated:</p>
            <ol>
                <li>If the method is not enabled on the route, deny</li>
                <li>Else if the user is a <code className="inlineCode">superUser</code>, grant access</li>
                <li>Else if the operation is allowed by default on the app, grant access</li>
                <li>Else if the operation is allowed on the user, grant access</li>
                <li>Else if the operation is allowed for the document owner and the user is the owner, grant access</li>
                <li>Else, <span className="textBold">implicit deny</span></li>
            </ol>
          </div>
        </div>
    )
  }