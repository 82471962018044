import { useEffect, useState } from "react"
import { firestore } from "../firebase/config"

export const useCounter = (collection) => {
    const [document, setDocument] = useState()
    const [total, setTotal] = useState(0)

    useEffect(()=>{
        if (collection){
            let query = firestore.collection(collection).orderBy('date','desc').limit(1)
        
            const unsubscribe = query.onSnapshot(snapshot => {
                if (!snapshot.empty && snapshot.docs[0].data().date.toDate().toISOString().split('T')[0] === new Date().toISOString().split('T')[0]){
                    setDocument(snapshot.docs[0])
                }
                return () => {
                    unsubscribe()
                } 
            })
        }
    },[collection])

    useEffect(()=>{
        let unsubscribe = () => {}
        if (document) {
            unsubscribe = document.ref.collection('shards').onSnapshot(results => {
                let tot =0
                for (const doc of results.docs) {
                    tot += doc.data().count
                }
                setTotal(tot)
            })
        }
        return () => unsubscribe()
    },[document])

  return { total }
}