import { useEffect, useState } from "react"

export const usePublicApps = () => {
    const [error, setError] = useState(null)
    const [apps, setApps] = useState([])

    useEffect(() => {

        const run = async () => {
            try {
                let allApps = []
                let page = 1
                while (true) {
                    // console.log(page)
                    const res = await fetch(
                        // `http://localhost:8080/api/v1/store/apps?page=${page}`,
                        `https://app.postget.dev/api/v1/store/apps?page=${page}`,
                        {
                            method: 'GET',
                            headers: {
                                Origin: 'https://postget.dev',
                                'Access-Control-Request-Method': 'GET'
                            }
                        }
                    )
        
                    const json = await res.json()
                    const appData = typeof json.response === "object" && json.response
                    
                    // no more pages
                    if (appData && (appData.length === 0 || appData.length < 100)) {
                        allApps = [...allApps, ...appData]
                        break
                    // more pages to pull
                    } else if ( appData ){
                        page += 1 
                        allApps = [...allApps, ...appData]
                    //  err
                    } else {
                        setError(json.response)
                        break
                    }
                }
                setApps(allApps)
    
            } catch {
                setError('Unable to fetch apps')
            }
        }

        run()

    }, [])

    return {apps, error}
}