import ApiDoc from '../../../components/apidoc/ApiDoc';
import { hydrateRouteDocData } from "../../../util/Util";
import PageNav from '../../../components/layout/PageNav';

const pageRoutes = {
  "apps":[
    {label:'- Apps Index',path:'/docs?view=apps#index'},
    {label:'- Apps Detail',path:'/docs?view=apps#detail'},
  ]
}

export default function AppsDoc() {
    const exampleObj = {
        'name':'Widget App',
        'description':'A widgets API',
        'limit':10000,
        "contactEmail": "sally@postget.dev",
        "public": false,
        "tags":[],
        "author":"Sally",
        'docId':'widget_app_api'
    } 
    const schema = {
        "additionalProperties": false,
        "properties": {
          "name": {
            "maxLength": 20,
            "minLength": 3,
            "type": "string"
          },
          "limit": {
            "maximum": 50000,
            "minimum": 100,
            "type": "string"
          },
          "description": {
            "maxLength": 500,
            "minLength": 3,
            "type": "string"
          },
          "docId": {
            "maxLength": 45,
            "minLength": 3,
            "type": "string"
          },
          "contactEmail":{
            "type":"string",
            "format": "email",
            "example": "sally@postget.dev"
          },
          "public":{
            "type":"boolean",
            "description":"Publish on app store"
          },
          "tags":{
              "type":"array",
              "maxItems": 10,
              "items": {
                  "type": "string",
                  "minLength":2,
                  "maxLength":20,
              }
          }
        },
        "required": [
          "name",
          "limit",
          'description',
          "contactEmail"
        ],
        "type": "object"
    }
    const apiData = {
        description:"An app represents a domain of routes, clients, permissions, and statistics. An app typically hosts many routes and users.",
        docId:'widget_app_api',
        id:'apps',
        methods: ['GET','POST','PUT','DELETE'],
        name: 'Apps',
        links: [{to:'/docs?view=auth',desc:'Review authentication'},{to:'/docs?view=errors',desc:'Review error messages'}],
        schema,
        exampleObj
    }
    return (
        <div className='flexDiv apiDocContainer'>
          <div className="splitArticle apiDoc">
              <div className='articleHead'></div>
              <div className='articleBody'>
                  <div className="articleCentered">
                      <ApiDoc apiData={hydrateRouteDocData(apiData, false)}/>
                  </div>
              </div>
          </div>
          <PageNav routeGroups={pageRoutes}/>
        </div>
    );
  }