import './App.css';

import { BrowserRouter, Route, Routes, Navigate, useLocation} from 'react-router-dom'
import Nav from './components/layout/Nav'
import { useAuthContext } from './hooks/useAuthContext'

import Test from './routes/test/Test'
import AppStore from './routes/appstore/AppStore';
import Login from './routes/login/Login';
import Signup from './routes/signup/Signup';
import Reset from './routes/reset/Reset'
import ApiKeys from './routes/apiKeys/ApiKeys'
import Documentation from './routes/documentation/Documentation'
import AppIndex from './routes/appIndex/AppIndex';
import About from './routes/about/About';
import NotFound from './routes/notFound/NotFound';
import Privacy from './routes/privacy/Privacy';
import Terms from './routes/terms/Terms';
import Contact from './routes/contact/Contact';
import Iframe from './routes/iframe/Iframe';
import ScrollToTop from './hooks/scroll';
import PubApp from './routes/app/PubApp';

function App() {
  const {user, authIsReady} = useAuthContext()
  return (
    <div className="App">
      {authIsReady && (
        <BrowserRouter>
          <ScrollToTop />
          <Nav/>
          <Routes>
            <Route path='/' exact element={<About/>}/>
            <Route path='/login' exact={true} element={user ? <Navigate to='/apikeys?view=secret'/> : <Login/>}/>
            <Route path='/signup' exact={true} element={user ? <Navigate to='/apikeys?view=secret'/> : <Signup/>}/>
            <Route path='/apikeys' exact={true} element={!user ? <Navigate to='/login'/> : <ApiKeys/>}/>
            <Route path='/home' exact={true} element={<About/>}/>
            <Route path='/docs' exact={true} element={<Documentation/>}/>
            <Route path='/apps' exact={true} element={!user ? <Navigate to='/login'/> : <AppIndex/>}/>
            <Route path='/reset' exact={true} element={user ? <Navigate to='/apikeys?view=secret'/> : <Reset/>}/>
            <Route path='/about' exact={true} element={<About/>}/>
            <Route path='/privacy' exact={true} element={<Privacy/>}/>
            <Route path='/terms' exact={true} element={<Terms/>}/>
            <Route path='/contact' exact={true} element={<Contact/>}/>
            <Route path='/iframe' element={<Iframe/>}/>
            <Route path='/appstore' element={<AppStore/>}/>
            <Route path='/appstore/:ownerId/:appId' element={<PubApp/>}/>
            <Route path='*' element={<NotFound/>}/>
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
