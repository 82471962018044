import ApiDoc from "../../../components/apidoc/ApiDoc"
import { hydrateRouteDocData } from "../../../util/Util"
import PageNav from '../../../components/layout/PageNav';

const pageRoutes = {
    "clients":[
      {label:'- Clients Index',path:'/docs?view=clients#index'},
      {label:'- Clients Detail',path:'/docs?view=clients#detail'},
    ]
}

export default function ClientDoc() {

    const exampleObj = {
        'name':'Jasper',
        'superUser':false,
        'permissions':{
            'read':['widget_route','protected_route'],
            'write':['widget_route']
        },
        'docId':'jasper_jenkins'
    }

    const schema = {
        "additionalProperties": false,
        "properties": {
          "name": {
            "maxLength": 20,
            "minLength": 3,
            "type": "string"
          },
          "superUser": {
            "type": "boolean"
          },
          "permissions": {
            "type": "object",
            "additionalProperties": false,
            "properties":{
                "read":{
                    "type":"array",
                    "items":{
                        "description": "route docIds",
                        "type":"string"
                    }
                },
                "write":{
                    "type":"array",
                    "items":{
                        "description": "route docIds",
                        "type":"string"
                    }
                },
                "update":{
                    "type":"array",
                    "items":{
                        "description": "route docIds",
                        "type":"string"
                    }
                },
                "add":{
                    "type":"array",
                    "items":{
                        "description": "route docIds",
                        "type":"string"
                    }
                },
                "delete":{
                    "type":"array",
                    "items":{
                        "description": "route docIds",
                        "type":"string"
                    }
                },
                "readOwned":{
                    "type":"array",
                    "items":{
                        "description": "route docIds",
                        "type":"string"
                    }
                },
                "updateOwned":{
                    "type":"array",
                    "items":{
                        "description": "route docIds",
                        "type":"string"
                    }
                },
                "deleteOwned":{
                    "type":"array",
                    "items":{
                        "description": "route docIds",
                        "type":"string"
                    }
                },
            },
            "description": "Can be an empty map"
          },
          "docId": {
            "maxLength": 45,
            "minLength": 3,
            "type": "string"
          }
        },
        "required": [
          "name",
          "superUser",
          'permissions'
        ],
        "type": "object"
    }
    
    const indexUrl =  <span>https://app.postget.dev/api/v1/owner/apps/<span className='textBold'>{'<appId>'}</span>/clients</span>
    const detailUrl = <span>https://app.postget.dev/api/v1/owner/apps/<span className='textBold'>{'<appId>'}</span>/clients/<span className='textBold'>{'<docId>'}</span></span>
    const indexParams = [
        {
            type:'URL path',
            variables:[
                {name:'appId',required:true,desc:'The unique id of the parent app object'}
            ]
        }
    ]
    const detailParams = [
        {
            type:'URL path',
            variables:[
                {name:'docId',required:true,desc:'The unique id of the target client'},
                {name:'appId',required:true,desc:'The unique id of the parent app object'}
            ]
        }
    ]

    const apiData = {
        description:'A client is a user or service account you may grant "consumer tier" access to your app. Clients are typically interested in performing HTTP operations and queries against your app routes. A client is associated with one app domain and is configured with a set of permissions.',
        docId:'jasper_jenkins',
        id:'clients',
        methods: ['GET','POST','PUT','DELETE'],
        name: 'Clients',
        links: [{to:'/docs?view=auth',desc:'Review authentication'},{to:'/docs?view=errors',desc:'Review error messages'}],
        schema,
        indexUrl,
        detailUrl,
        indexParams,
        detailParams,
        exampleObj
    }

    return (
        <div className='flexDiv apiDocContainer'>
            <div className="splitArticle apiDoc">
                <div className='articleHead'></div>
                <div className='articleBody'>
                    <div className="articleCentered">
                        <ApiDoc apiData={hydrateRouteDocData(apiData, false)}/>
                    </div>
                </div>
            </div>
            <PageNav routeGroups={pageRoutes}/>
        </div>
    )
    
}