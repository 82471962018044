import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import "firebase/analytics"

const firebaseConfig = {
    apiKey: "AIzaSyBqWPchXzh0urZm_C1j3xAYNDxg2xMLm8w",
    authDomain: "minheap-bb80e.firebaseapp.com",
    projectId: "minheap-bb80e",
    storageBucket: "minheap-bb80e.appspot.com",
    messagingSenderId: "819676414074",
    appId: "1:819676414074:web:6f0ea666cef7c7b3df7bc3",
  }

// init firebase
const app = firebase.initializeApp(firebaseConfig)
firebase.analytics()

// init services
const firestore = firebase.firestore() 
const auth = firebase.auth()
const timestamp = firebase.firestore.Timestamp
const storage = firebase.storage()

export { firestore, auth, timestamp, storage }