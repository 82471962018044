import { useEffect, useState } from "react"
import { firestore } from "../firebase/config"

export const useCollection = (collection, _query, _orderBy, _limit) => {
  const [documents, setDocuments] = useState(null)
  const [error, setError] = useState(null)

  // if we don't use a ref --> infinite loop in useEffect
  // _query is an array and is "different" on every function call
  const [query,setQuery] = useState(_query)
  const [orderBy,setOrderBy] = useState(_orderBy)

  useEffect(() => {

    if (collection) {

      let ref = firestore.collection(collection)

      if (query) {
        ref = ref.where(...query)
      }
      if (orderBy) {
        ref = ref.orderBy(...orderBy)
      }
      if (_limit) {
        ref = ref.limit(_limit)
      }

      const unsubscribe = ref.onSnapshot(snapshot => {
        let results = []
        snapshot.docs.forEach(doc => {
          results.push({...doc.data(), id: doc.id, ref:doc.ref})
        })
        // update state
        setDocuments(results)
        setError(null)
      }, error => {
        setError('could not fetch the data')
      })
      // unsubscribe on unmount
      return () => unsubscribe() 
    }
  }, [collection, query, orderBy])

  const deleteDocument = async (doc)=>{
    await firestore.collection(collection).doc(doc).delete()
  }

  return { documents, error, deleteDocument, orderBy, setOrderBy, setQuery }
}