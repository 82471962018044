import './ApiDoc.css'
import ApiRoute from './ApiRoute'
import { Link } from 'react-router-dom'
import { nanoid } from 'nanoid'

export default function ApiDoc({apiData, dropSelect}) {
  const {
    name,
    resourceDesc,
    links,
    routes
  } = apiData

  return (
    <div className='appDoc articleTextSmall'>
        <div className='apiDocHeader'>
          <div className="appIndexTitle">
              <h2 className=""><span className="material-symbols-outlined icon">alt_route</span>{`${name}`}</h2>
              {dropSelect}
          </div>
          <p className="articleText"><em>"{resourceDesc}"</em></p>
          <div className='articleLinks'>
            <p className="articleText articleLinks">
              {links.map((link)=>{
                return <Link to={link.to} className='appLink textBold' key={nanoid()}>
                  [{link.desc}]
                </Link>
              })}
            </p>
          </div>
        </div>
        {routes.map((route, index)=>{
          return <span key={nanoid()}><ApiRoute route={route} last={index === routes.length -1}/></span>
        })}
    </div>   
  )
}