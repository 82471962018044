import CodeBox from "../../../components/extra/CodeBox";
import {Link} from 'react-router-dom'
import InfoBox from "../../../components/extra/InfoBox";

const ascii = `########################################
#                                      #
#    ^..^      /                       #
#    /_/\\_____/                        #
#       /\\   /\\                        #
#      /  \\ /  \\  Anim@l_Cr0ss'ng$!    #
#                                      #
########################################`
export default function HowToDoc() {
    const exampleCode = {
        imports: `import requests
    
url = "https://app.postget.dev/api/v1/owner"
key = "xZbC7L8RWkjFgah8Tli6"
headers = {"Authorization": f"Basic {key}"}
app_id = "animal_crossings"`,
        app: `animal_crossings_app = {
    "name": "Animal Crossings",
    "description": "The most cutting edge platform for tracking and celebrating animal sightings",
    "contactEmail": "example@postget.dev"
    "limit": 10000,
    "docId": app_id
}
    
r = requests.post(url + "/apps", json = animal_crossings_app, headers = headers)
print(r.json())`,
        locationsSchema: `locations_schema = {
    "type": "object",
    "additionalProperties": False,
    "properties": {
        "name": {
            "type": "string",
            "minLength": 3,
            "maxLength": 50
        },
        "description": {
            "type": "string",
            "minLength": 3,
            "maxLength": 200
        }
    },
    "required": [
        "name",
        "description"
    ]
}`,
        locationsRoute: `locations_route = {
    "name": "Locations",
    "description": "This route hosts a collection of locations where animal sightings may have taken place...",
    "methods": [
        "GET",
        "POST",
        "PUT",
        "DELETE"
    ],
    "schema": locations_schema,
    "docId": "locations"
}

r = requests.post(url + f"/apps/{app_id}/routes", json = locations_route, headers = headers)
print(r.json())`,
        sightingsSchema: `sightings_schema = {
    "type": "object",
    "additionalProperties": False,
    "properties": {
        "location": {
            "type": "string",
            "minLength": 3,
            "maxLength": 50
        },
        "animal_type": {
            "type": "string",
            "enum": ["beaver", "seagull", "possum"]
        },
        "datetime": {
            "type": "object",
            "properties": {
                "<dateTime>": {
                    "type": "string",
                    "format": "date-time"
                }
            }
        }
    },
    "required": [
        "location",
        "animal_type",
        "datetime"
    ]
}`,
        sightings_Route: `sightings_route = {
    "name": "Sightings",
    "description": "This route hosts a collection of animal sightings. A sighting represents a location, animal type, and time",
    "methods": [
        "GET",
        "POST",
        "PUT",
        "DELETE"
    ],
    "schema": sightings_schema,
    "docId": "sightings"
}

r = requests.post(url + f"/apps/{app_id}/routes", json = sightings_route, headers = headers)
print(r.json())`,
        sally: `sally = {
    "name": "Sally",
    "superUser": True,
    "permissions": {
        "read": [
            "locations",
            "sightings"
        ],
        "write": [
            "locations",
            "sightings"
        ]
    },
    "docId": "sally"
}
    
r = requests.post(url + f"/apps/{app_id}/clients", json = sally, headers = headers)
print(r.json())`,
        jake: `jake = {
    "name": "Jake",
    "superUser": False,
    "permissions": {
        "read": [
            "locations",
            "sightings"
        ],
        "write": [
            "locations",
            "sightings"
        ]
    },
    "docId": "jake"
}
    
r = requests.post(url + f"/apps/{app_id}/clients", json = jake, headers = headers)
print(r.json())`,
        possumsInc: `possums_inc = {
    "name": "Possums Incorporated",
    "superUser": False,
    "permissions": {
        "read": [
            "sightings"
        ],
        "write": []
    },
    "docId": "possums_inc"
}

r = requests.post(url + f"/apps/{app_id}/clients", json = possums_inc, headers = headers)
print(r.json())`,
        accessKeys: `key_url = "https://app.postget.dev/api/v1/keys"

r = requests.get(key_url + f"/sally?appId={app_id}", headers = headers)
print(r.json()["response"]["apiKey"])

r = requests.get(key_url + f"/jake?appId={app_id}", headers = headers)
print(r.json()["response"]["apiKey"])

r = requests.get(key_url + f"/possums_inc?appId={app_id}", headers = headers)
print(r.json()["response"]["apiKey"])`,
        iframe:`<iframe src = "https://postget.dev/iframe?apiKey=xZbC7L8RWkjFgah8Tli6&appId=animal_crossings&routeId=sightings" title = "API Doc"></iframe>`,
        clientPost:`import requests
from datetime import datetime

client_url = "https://app.postget.dev/api/v1/client"
client_key = "7eEqj4AyK15tVr16RIQ9"
client_headers = {"Authorization": f"Basic {client_key}"}

new_location = {
    "name": "Ludwig's Ice Cream Shop",
    "description": "Ludwig's Ice Cream Shop on 5th street and central avenue"
}

r = requests.post(client_url + "/locations", json = new_location, headers = client_headers)
print(r.json())`,
        clientResponse:`{
    "response": {
        "name": "Ludwig's Ice Cream Shop",
        "description": "Ludwig's Ice Cream Shop on 5th street and central avenue",
        "docId": "LuL1v1l2ILkp1MSgkfNd",
        "exists": true
    }
}`,
        clientSecondPost:`new_sighting = {
    "location": "LuL1v1l2ILkp1MSgkfNd",
    "animal_type": "beaver",
    "datetime": {
        "<dateTime>": datetime.now().isoformat()
    }
}

r = requests.post(client_url + "/sightings", json = new_sighting, headers = client_headers)
print(r.json())`,
        clientSecondResponse:`{
    "response": {
        "animal_type": "beaver",
        "datetime": {
            "<dateTime>": "2023-01-20T19:25:04.769362+00:00"
        },
        "docId": "9nB2XbSSTg8t-PZQ9L_nu",
        "exists": true,
        "location": "LuL1v1l2ILkp1MSgkfNd"
    }
}`
    
    }
    return (
    <div className="howTo Article">
        <div className="articleCentered articleText">
            
            <h3 className="articleTitle">
                Building a Demo App <span className="successText smallText"><span className="material-symbols-outlined smallText">edit</span>Beginner</span>
            </h3>
            <p>
            👋 Hello, team... in this article, we'll walk through the build process for a sample app that tracks animal sightings in our town. Let's call this app... 
                <span className="textBold">Animal Crossings!</span> 
            </p>
            <a  href="https://github.com/aaronhough/postget_demo" className="btn smallText  btnMedium sourceCodeBtn"><i className="fa fa-github"></i> <span>project source code</span></a>
            <div className="exampleAscii">
                <CodeBox lines={false} codeText={ascii} headerView={false} language='json'/> 
            </div>             
            <h3 className="articleSubTitle flexTitle" id="usecase"><span className="material-symbols-outlined icon">temp_preferences_custom</span>The use case:</h3>
            <p>
                Animal Crossings is a simple app... We have two users named Jake and Sally who are out and about town scouring for new animal sightings and recording them on our platform. There are two types of records we want our users to record... locations and encounters.
            </p>
            <p>
                A location represents a... geographic location... and an encounter represents a recorded animal sighting with attributes animal type, location, and timestamp. At this time, our app only supports sightings for beavers, seagulls, and possums... though in time our platform will scale to include otters, llamas, and meerkats.
            </p>
            <p>
                We'll start off with two routes... one for locations and one for encounters. We'll use a schema on our encounter route to limit the animal type input from our users. We also recently entered a strategic business relationship with Possums Incorporated so we'll be giving them read only access to our encounters collection.
            </p>
            <p>
                Oh... and Sally was recently promoted to a lead role so we'll give her admin access over our app so she can update our app settings, routes, and other user accounts.
            </p>
            <InfoBox color='Primary' text="Btw... I highly recommend opening your MyApps page in a separate window while you follow this tutorial... simply because it's really cool to watch your application come alive in real time!" icon='star'/>

            <h3 className="articleSubTitle flexTitle" id="start"><span className="material-symbols-outlined icon">flag</span>Getting started</h3>
            <p>This article is written in python but feel free to use whichever language you're most comfortable with. We'll start off by importing the requests library to interact with the postget API. We then declare a few variables which will be referenced throughout the rest of the build. If you're following along, replace the value for the key variable with your unique key.</p>
            <Link to='/apikeys?view=secret' className='appLink textBold'>{'[ My key ]'}</Link>
            <CodeBox lines={true} codeText={exampleCode.imports} codeTitle='Initialize app' headerView={false} language='python'/>              
            
            <h3 className="articleSubTitle flexTitle" id="app"><span className="material-symbols-outlined icon">widgets</span>Building the app</h3>
            <p>All we have to do to initialize the live application is post some <code className="inlineCode textBold">JSON</code> to the app endpoint. Here we specify an official <code className="inlineCode textBold">name</code>, a <code className="inlineCode textBold">description</code>, a <code className="inlineCode">contactEmail</code> address, and a user daily <code className="inlineCode textBold">limit</code> value. We use the <code className="inlineCode textBold">app_id</code> variable set in the last step as the <code className="inlineCode textBold">docId</code> of this app. We then pass in the previously defined headers so that the API will be able to properly authenticate the request.</p>
            <CodeBox lines={true} codeText={exampleCode.app} codeTitle='Building the app' headerView={false} language='python'/>              
            
            <h3 className="articleSubTitle flexTitle" id="firstroute"><span className="material-symbols-outlined icon">alt_route</span>Building the first route</h3>
            <p>For the sake of clarity, we're going to divide our route builds into two steps. First we'll define a route schema and then we'll initialize the route. Starting with the locations route, we define the schema below according to standard json schema specifications.</p>
            <p>
                We will require exactly two fields on all the records hosted by this route... <code className="inlineCode textBold">name</code> and <code className="inlineCode textBold">description</code>. Both of these attributes are required to be strings longer than five characters. The <code className="inlineCode textBold">name</code> attribute has a length limit of fifty characters and the <code className="inlineCode textBold">description</code> attribute has a limit of two hundred.
            </p>
            <CodeBox lines={true} codeText={exampleCode.locationsSchema} codeTitle='Defining schema' headerView={false} language='python'/>
            <p>For the second step, we define our actual route config. The route has a proper <code className="inlineCode textBold">name</code>, a <code className="inlineCode textBold">description</code>, and a list of allowed <code className="inlineCode textBold">HTTP</code> <code className="inlineCode textBold">methods</code>. We also set a <code className="inlineCode textBold">docId</code> on the route record of "locations" which we can use as the target for future updates against this route object.</p>
            <p>
                Finally, we add the schema defined above to the route config and then we post the payload to the routes endpoint with our predefined headers. Note that this route endpoint is a sub route of the app entity we created earlier... and the <code className="inlineCode textBold">docId</code> of our app is injected into the URL path via the <code className="inlineCode textBold">app_id</code> variable.
            </p>
            <CodeBox lines={true} codeText={exampleCode.locationsRoute} codeTitle='Building a route' headerView={false} language='python'/>              
            
            <h3 className="articleSubTitle flexTitle" id="secondroute"><span className="material-symbols-outlined icon">alt_route</span>Building the second route</h3>
            <p>
                To build the second route for sightings, we'll follow the same general process but with a few added tricks. First we configure the schema. We require three attributes on all records... <code className="inlineCode textBold">location</code>, <code className="inlineCode textBold">animal_type</code>, and <code className="inlineCode textBold">datetime</code>. The <code className="inlineCode textBold">location</code> attribute is a simple string. 
            </p>
            <p>
                You may recall, our app only supports sightings for certain animal types... to accomplish this, we specify an enum setting for <code className="inlineCode textBold">animal_type</code> and include the supported options as an array of strings. This is only one example of what you can do with json schema. json schema is a powerful tool... to read more about what's possible with json schema, <a href={'https://python-jsonschema.readthedocs.io/en/stable/'} className='appLink textBold'> check out this documentation.</a>
            </p>
            <p>
                The last attribute is called <code className="inlineCode textBold">datetime</code> and represents the time of the sighting. We want to store this data as an actual datetime object so we can order/filter our future query results accordingly... but in order to do so, we need to use special conventions.
            </p>
            <p>
                When the data is sent to the API, it's sent in <code className="inlineCode textBold">JSON</code> format which means the data needs to be serialized. The postget API looks for objects with special keys to determine if some data should be deserialized into a complex type such as a date. To accomplish the desired effect, we'll use postget serialization conventions to define our <code className="inlineCode textBold">datetime</code> attribute... <Link  to='/docs?view=serialization' className='appLink textBold'>Review more details about serialization conventions here.</Link>
            </p>
            <CodeBox lines={true} codeText={exampleCode.sightingsSchema} codeTitle='Defining schema' headerView={false} language='python'/>
            <InfoBox color='Yellow' text="Note there is currently no restriction on the data layer to force the sighting.location attribute to match an existing docId of a location record." icon='bulb'/>
            <p>The remaining work for our second route is nothing we haven't seen before... simply define some high level properties of the route, link the schema object, and post the payload to the routes endpoint.</p>  
            <CodeBox lines={true} codeText={exampleCode.sightings_Route} codeTitle='Building a route' headerView={false} language='python'/>              

            <h3 className="articleSubTitle flexTitle" id="users"><span className="material-symbols-outlined icon">person</span>Adding our users</h3>
            <p>
                Adding users is pretty straight forward. The user config has a <code className="inlineCode textBold">name</code>, a <code className="inlineCode textBold">docId</code>, a <code className="inlineCode textBold">superUser</code> designation, and <code className="inlineCode textBold">permissions</code>. The <code className="inlineCode textBold">superUser</code> designation should always be set to False unless the user in question should have admin access to their respective app. 
            </p>
            <p>
                If a user is given <code className="inlineCode textBold">superUser</code> designation, that user can essentially do anything the owner can within the app domain including editing/deleting routes and users... so be careful when setting this option.
            </p>
            <p>
                For <code className="inlineCode textBold">permissions</code>, there are always two sub categories... <code className="inlineCode textBold">read</code> (GET) and <code className="inlineCode textBold">write</code> (PUT, POST, DELETE). The value of each permission is an array of strings which should correspond to <code className="inlineCode textBold">docId</code> attributes of the routes in question.
            </p>
            <p>
                Our first user Sally will have read and write permissions to both routes "locations" and "sightings". Per the defined specifications, we'll also designate her account as a <code className="inlineCode textBold">superUser</code> since she's in a lead role. To create the user, we post the config to the clients endpoint... another sub route of our app.
            </p>
            <CodeBox lines={true} codeText={exampleCode.sally} codeTitle='Adding a user' headerView={false} language='python'/>   
            <p>To create Jake's account, we can copy/paste the config we used for Sally then update the  <code className="inlineCode textBold">name</code> and  <code className="inlineCode textBold">docId</code>... and of course we will set  <code className="inlineCode textBold">superUser</code> to False then post the config to the users endpoint.</p>           
            <CodeBox lines={true} codeText={exampleCode.jake} codeTitle='Adding a user' headerView={false} language='python'/>              
            
            <h3 className="articleSubTitle flexTitle" id="readonly"><span className="material-symbols-outlined icon">menu_book</span>Adding a read only account</h3>
            <p>We have one more account to add... Possums Incorporated! This account will have a very limited view... We set the <code className="inlineCode textBold">superUser</code> designation to False and include <span className="textBold">only</span> the "sightings" route in the read permission.</p>
            <CodeBox lines={true} codeText={exampleCode.possumsInc} codeTitle='Read only user' headerView={false} language='python'/>    
            <p>And that's it! Our app is built and online! let's check it out...</p>          
            
            <h3 className="articleSubTitle flexTitle" id="keys"><span className="material-symbols-outlined icon">key</span>Accessing the keys</h3>
            <p>
                It's now time to provide our users with their keys... we can export the keys and store them in a safe place where our users can access them. To get the keys, we'll issue a get request against the "keys" endpoint where each user has their own dedicated route. 
            </p>
            <p>
                Since it is possible for us to host two users with the same name on different apps, we'll need to specify the <code className="inlineCode textBold">appId</code> URL query parameter... which we'll set as the variable <code className="inlineCode textBold">app_id</code> from earlier.
            </p>
            <CodeBox lines={true} codeText={exampleCode.accessKeys} codeTitle='Accessing keys' headerView={false} language='python'/>              
            
            <h3 className="articleSubTitle flexTitle" id="integrate"><span className="material-symbols-outlined icon">code</span>Integrations</h3>
            <p>Our users are going to need documentation on how to use our new app... luckily for us, postget is already providing API documentation for our app that reflects the unique real time state. We can inject a visual reference to this documentation directly into our existing platform with an iframe tag. If we make any changes to the underlying app in the future, the visual documentation will update automatically!</p>
            <p>Note that there is a dedicated URL endpoint for hosting iframes. We use this URL and set the following URL query parameters: <code className="inlineCode textBold">apiKey</code>, <code className="inlineCode textBold">appId</code>, and <code className="inlineCode textBold">routeId</code>. The <code className="inlineCode textBold">apiKey</code> should be your unique private key.</p>
            <CodeBox lines={true} codeText={exampleCode.iframe} codeTitle='iFrame' headerView={false} language=''/>              
            <p>Don't have your own UI? Not a problem. Check out this article on how to <Link className="appLink textBold" to="/docs?view=appstore">publish your app</Link> on the postget.dev app store for free hosting.</p>

            <h3 className="articleSubTitle flexTitle" id="verify"><span className="material-symbols-outlined icon">done</span>Verification</h3>
            <p>To test our app, we can issue a <code className="inlineCode textBold">POST</code> request with Sally's key to our "locations" route as follows... Note that when we act as an API client rather than an API owner, the URL path changes to reflect that we're accessing the client side.</p>
            <CodeBox lines={true} codeText={exampleCode.clientPost} codeTitle='Client POST' headerView={false} language='python'/>              
            <p>👍 We should get a response like this indicating our new location is <span className="textBold">live!</span></p>
            <CodeBox lines={true} codeText={exampleCode.clientResponse} codeTitle='Client response' headerView={false} language='json'/>    
            <p>For good measure, let's make sure we can post a sighting record... here is the paylod:</p>
            <CodeBox lines={true} codeText={exampleCode.clientSecondPost} codeTitle='Client POST' headerView={false} language='python'/>    
            <p>If all goes well, we get a response like this. Our datetime has been properly serialized which means we can sort our records chronologically later... great! 🙌</p>
            <CodeBox lines={true} codeText={exampleCode.clientSecondResponse} codeTitle='Client POST' headerView={false} language='json'/>    
            <p>And that's it! Congrats on your first build... <span className="textBold">cheers to many more! 🍻</span></p>
        </div>
    </div>)
}