import { useEffect, useState } from "react"
import { useCollection } from "./useCollection"

export const useAppShard = (path) => {
    //  use collection to shard... aggregate data... 
    const [data, setData] = useState({})
    const {documents: shards} = useCollection(path || null)
    useEffect(()=>{
        const hits = {}
        shards?.forEach((shard)=>{
            if (shard.hits) {
                Object.keys(shard.hits).forEach((user)=>{
                    if (!hits[user]) {
                        hits[user] = {}
                    }
                    Object.keys(shard.hits[user]).forEach((action)=>{
                        if (!hits[user][action]) {
                            hits[user][action] = 0
                        }
                        hits[user][action] += shard.hits[user][action]
                    })
                })
            }
        })
        setData(hits)
    },[shards])
    return {data}
}

export const useAppStatShards = (shardPaths) => {
    // given shard paths, stream shard collections...
    // return aggregated shard data..
    const [data, setData] = useState([])
    const {data:dataZero} = useAppShard(shardPaths && shardPaths[0])
    const {data:dataOne} = useAppShard(shardPaths && shardPaths[1])
    const {data:dataTwo} = useAppShard(shardPaths && shardPaths[2])
    const {data:dataThree} = useAppShard(shardPaths && shardPaths[3])
    const {data:dataFour} = useAppShard(shardPaths && shardPaths[4])
    const {data:dataFive} = useAppShard(shardPaths && shardPaths[5])
    const {data:dataSix} = useAppShard(shardPaths && shardPaths[6])
    const {data:dataSeven} = useAppShard(shardPaths && shardPaths[7])
    const {data:dataEight} = useAppShard(shardPaths && shardPaths[8])
    const {data:dataNine} = useAppShard(shardPaths && shardPaths[9])
    const {data:dataTen} = useAppShard(shardPaths && shardPaths[10])
    const {data:dataEleven} = useAppShard(shardPaths && shardPaths[11])
    const {data:dataTwelve} = useAppShard(shardPaths && shardPaths[12])
    const {data:dataThirteen} = useAppShard(shardPaths && shardPaths[13])

    useEffect(()=>{
        setData(
            [dataZero,dataOne,dataTwo,dataThree,dataFour,dataFive,
                dataSix,dataSeven,dataEight,dataNine,dataTen,dataEleven,
                dataTwelve,dataThirteen]
        )
    },[dataZero,dataOne,dataTwo,dataThree,dataFour,dataFive,dataSix,
        dataSeven,dataEight,dataNine,dataTen,dataEleven,dataThirteen])
    return {data}
}

export const useAppStats = (userId,appId) => {
    const {documents: dailyStats} = useCollection(`users/${userId}/apps/${appId}/dailyStats`, null, ['date','desc'], 14)
    const [flatData, setFlatData] = useState([])
    const [statShardPaths, setStatShardPaths] = useState([])
    const {data: aggDailyStats} = useAppStatShards(statShardPaths)
    useEffect(()=>{
        setStatShardPaths(dailyStats?.map((doc)=>doc.ref.path+'/shards'))
    },[dailyStats])

    useEffect(()=>{

        if (aggDailyStats?.length && dailyStats && dailyStats.length){
            setFlatData(aggDailyStats.map((item, index) => {
                return {
                    hits:item,
                    ...dailyStats[index]
                }
            }))
            // [{date,hits}]
            // date is on dailyStats doc hit is on aggDailyStats doc... indexes should match

        } else {
            setFlatData([])
        }
    },[aggDailyStats, dailyStats])
    // return dailyStats reversed...
    return {flatData}
}