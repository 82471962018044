import './ApiSchema.css'
import ExampleBox from '../extra/ExampleBox'
import { transformSchema } from '../../util/Util'

export default function ApiSchema({schema}) {

  return (
    <div className='apiSchema'>
        <h4><span className="material-symbols-outlined icon">warning</span>Enforced schema</h4>
        <ExampleBox examples={[transformSchema(schema)]} copyButton={true} flatText={JSON.stringify(schema,null,4)}/>
    </div>
  )
}