import { createContext, useState, useEffect } from 'react'
import { useAuthContext } from '../hooks/useAuthContext'
import { useApp } from '../hooks/useApp'
import { useCollection } from '../hooks/useCollection'

export const AppContext = createContext()

export const AppContextProvider = ({ children }) => {
  const value = ProvideAppContext()
  return (
    <AppContext.Provider value={value}>
      { children }
    </AppContext.Provider>
  )

}

const ProvideAppContext = () => {

    const [app, setApp] = useState(null)
    const {user} = useAuthContext()
    const {documents: appDocuments} = useCollection(`users/${user?.uid}/apps`)

    const { data } = useApp(app)

    useEffect(()=>{
      if (appDocuments?.length && !app) {
        setApp({label:appDocuments[0].name, value:appDocuments[0].id})
      }
    },[appDocuments, app])

    return {
        app,
        setApp,
        appDocuments,
        data
    }
}