import './ArticleSection.css'
import { nanoid } from 'nanoid'

export default function ArticleSection({title, rows, titleClass, topTable, footer}) {
  return (
    <div className={`table ${topTable ? 'topTable' : ''}`}>
        {title && <h2 className={`articleSubTitle tableTitle ${titleClass && titleClass}`}>{title}</h2>}
        <div className={'articleSection'}>
            {rows.map((row)=>{
                return <div key={nanoid()} className='tableRow'>{row}</div>
            })}
            {footer}
        </div>
    </div>
  )
}