import './ActivityFeed.css'
import { nanoid } from 'nanoid'
import Tag from '../generic/Tag'
import { methodColorMap } from '../../util/Util'
import { useState, useEffect } from 'react'
import FeedNav from './FeedNav'

const parsePath = (path) => {
    const digits = path.split('/')
    if (path.includes('api/v1/keys/')){
        return '/'+digits.slice(3,digits.length).join('/')
    }
    return '/'+digits.slice(4,digits.length).join('/')
}

export default function ActivityFeed({feed,pageSize=20}) {
    const [page, setPage] = useState(0)
    const [totalPages, setTotalPages] = useState(0)

    useEffect(()=>{
        setTotalPages(feed.length/pageSize)
    },[feed,pageSize])

  return (
    <div className='activityFeed'>
        <h3 className='articleSubTitle'>Activity feed</h3>
        <table className='feed'>
            {/* <thead className='articleText'>
                <tr>
                    <th>METHOD</th>
                    <th>ROUTE</th>
                    <th>USER</th>
                    <th>AT</th>
                </tr>
            </thead> */}
            <tbody>
            {feed.slice(page*pageSize,((page*pageSize)+pageSize)).map((item,index)=>{
                return (
                    <tr key={nanoid()} className={`feedItem ${index%2===0 ? 'evenRow' : 'oddRow'}`}>
                        <td>
                            <Tag text={item.method} color={methodColorMap[item.method] || 'Default'}/>
                        </td>
                        <td className='routeCol'>
                            <p className='articleText feedText'>{parsePath(item.resource)}</p>
                        </td>
                        <td className='userCol'>
                            <p className='articleText feedText'>{item.user}</p>
                        </td>
                        <td>
                            <p className='date feedText'>{new Date(item.date.seconds * 1000).toISOString().split('.')[0]}</p>
                        </td>
                    </tr>
                )
            })}
            </tbody>
        </table>
        <FeedNav page={page} setPage={setPage} pageSize={pageSize} feedLength={feed.length} totalPages={totalPages}/>
    </div>
  )
}