import './InfoBox.css'

const icons = {
  info:<span className="material-symbols-outlined infoIcon">info</span>,
  bulb:<span className="material-symbols-outlined infoIcon">lightbulb</span>,
  star:<span className="material-symbols-outlined infoIcon">star</span>
}
export default function InfoBox({color,text,icon}) {
  return (
    <div className={`infoBox infoBox${color}`}>
        <div className='infoContainer'>
            {icons[icon] || icons['info']}
            <div className='articleText'>{text}</div>
        </div>
    </div>
  )
}