import { useState } from 'react'
import { firestore } from "../../firebase/config"

export default function ContactForm({}) {
    const [from, setFrom] = useState('')
    const [title, setTitle] = useState('')
    const [message, setMessage] = useState('')
    const [feedback, setFeedback] =  useState(null)

    const handleSubmit = async (e) => {
        e.preventDefault()
        const r = await firestore.collection('contact').add({
            from,
            title,
            message,
            date: new Date()
        })
        console.log(r)
        setFrom('')
        setTitle('')
        setMessage('')
        setFeedback("👍 Thanks! We'll review soon.")
    }

    return (
        <div className='authForm'>
            <form onSubmit={handleSubmit}>
                <h2 className='articleSubTitle authTitle'>Contact us!</h2>
                <label>
                    <span>from</span>
                    <input 
                    type="text"
                    required
                    onChange={(e)=>setFrom(e.target.value)}
                    value={from}
                    minLength={2}
                    maxLength={80}
                    />
                </label>
                <label>
                    <span>title</span>
                    <input 
                    type="text"
                    required
                    onChange={(e)=>setTitle(e.target.value)}
                    value={title}
                    minLength={2}
                    maxLength={80}
                    />
                </label>
                <label>
                    <span>message</span>
                    <textarea 
                    required
                    onChange={(e)=>setMessage(e.target.value)}
                    value={message}
                    minLength={3}
                    maxLength={1000}
                    />
                </label>
                {feedback && <p className='articleText'>{feedback}</p>}
                <button className="btn">Send</button>
            </form>
            <div className='emailTag'><p className='articleText'>Or via direct email -> <span className='textBold'>admin@postget.dev</span></p></div>
        </div>
    )
}