import { useEffect, useState } from "react"
import { useAuthContext } from './useAuthContext'
import { useDocument } from "./useDocument"
import { useCollection } from "./useCollection"
import { useAppStats } from "./useAppStats"

export const useApp = (app) => {
    const {user} = useAuthContext()
    const [appData, setAppData] = useState({
                                        app:{},
                                        routes:[],
                                        consumers:[],
                                        statistics:{'dailyStats':[], 'feed':[]}
                                    })
    const [appId, setAppId] = useState(app?.value)

    const {document: appDoc} = useDocument(`users/${user?.uid}/apps`,appId)
    const {documents: routeDocs} = useCollection(`users/${user?.uid}/apps/${appId}/routes`)
    // cannot continue to read consumer docs like this if we are holding keys in there
    // const {consumerDocs, error:clientError} = useClients(appId)
    const {documents: consumerDocs} = useCollection(`users/${user?.uid}/apps/${appId}/users`)
    const {flatData: dailyStats} = useAppStats(user?.uid, appId)
    // const {documents: dailyStats} = useCollection(`users/${user?.uid}/apps/${appId}/dailyStats`)
    const {documents: feed} = useCollection(`users/${user?.uid}/apps/${appId}/feed`,null,['date','desc'],250)

    useEffect(()=>{
        if (app && app?.value !== appId){
            setAppId(app.value)
        }
        if (appDoc?.exists) {
            setAppData({
                app: appDoc,
                routes: routeDocs,
                consumers: consumerDocs,
                statistics: {'dailyStats':dailyStats, 'feed':feed}
            })
        }
        else {
            setAppData({
                app:{},
                routes:[],
                consumers:[],
                statistics:{'dailyStats':[], 'feed':[]}
            })
        }
    },[app,user?.uid,appDoc,routeDocs,consumerDocs,dailyStats,feed])

    return {data:appData}
}