import Select from 'react-select';

const selectStyles = {
    control: styles => ({ ...styles, boxShadow: `0 0 0 0px var(--primary-color)`, backgroundColor: '#fff', border: '1px solid var(--vdark-grey-color)', 'borderRadius':'3px' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    //   const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor:  isSelected ? 'var(--primary-color)' : isFocused ? 'var(--primary-highlight)' : '#fff',
        color:  isSelected ? '#fff' : isFocused ? 'var(--vdark-grey-color)' : '#444',
        cursor: isDisabled ? 'not-allowed' : 'default'
      }
    }
}

export default function DropSelect({options, onChange, id, placeholder='Select', value, unset=true}) {
    const preOptions = unset ? [{label:'Select',value:'__select__'}] : []

    return (
      <div className='select-box'>
        <Select
            options={options && 
              Object.keys(options).length > 0 ? [...preOptions,...options] : [{label:'Select',value:'__select__'}]}
            defaultValue={'Select'}
            maxMenuHeight={250}
            onChange={onChange}
            id={id}
            styles={selectStyles}
            placeholder={placeholder}
            value={value || {label:'Select',value:value}}
        />
      </div>
    )
}