import './Modal.css'
import Button from './Button'

export default function Modal({setShowModal, onConfirm, header='modal header', 
    message='modal message',copyText='', height=200, type='confirm', route='/apiKeys?view=secret', tone="Danger"}) {
    
    const handleClick = () =>{
        setShowModal(false)
    }
    const handleConfirm = () =>{
        onConfirm()
        setShowModal(false)
    }
    return (
            <div className={`modal-container ${type}Modal`}>
                <div className='modal' style={{top:`${height}px`,position:'absolute'}}>
                    <div className='modal-header'>
                        {/* <img alt='x' onClick={handleClick} src={Cancel}></img> */}
                        <span className='material-symbols-outlined modalX' onClick={handleClick}>
                            close
                        </span>
                        <h5 className='page-title modalTitle articleText'>{header}</h5>
                    </div>
                    <div className='modalContent articleText'>
                        {type === 'form' && <div>{message}</div>}
                        {type==='confirm' && <div>{message}</div>}
                        {type==='display' && <pre>{message}</pre>}
                    </div>
                    {type !== 'form' && (
                        <div className='modal-buttons'>
                            <Button route={route} color='Light' size='Medium' text='cancel' onClick={handleClick}/>
                            {type === 'confirm' && (
                                <Button route={route} color={tone} size='Medium' text='yes' onClick={handleConfirm}/>
                            )}
                            {type === 'display' && (
                                <Button route={route} color='Primary' size='Medium' text='Copy' onClick={()=>{
                                    navigator.clipboard.writeText(copyText || message)
                                    handleClick()
                                }}/>
                            )}
                        </div>
                    )}
                </div>
            </div>
        )
 }