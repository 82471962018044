import CodeBox from "../../../components/extra/CodeBox"

const codeExample = `headers = {'Authorization': 'Basic sample-key'}

limit = 500
start = None

total_set = []
base_url = f'https://app.postget.dev/api/v1/client/large_route?limit={limit}'

r = requests.get(base_url, headers=headers)

local_set = r.json()['response']
total_set.extend(local_set)
start = local_set[-1]['docId']

# so long as the local set is as large as the limit, continue to 
# fetch an additional page 
while len(local_set) == limit:

    r = requests.get(base_url + f'&start={start}', headers=headers)
    local_set = r.json()['response']
    
    # the first returned record is one we've already seen so we slice 
    # and update our total
    total_set.extend(local_set[1:])
    start = local_set[-1]['docId']`
export default function PageDoc() {
    return (
        <div className="Article articleText pagination">
            <div className="articleCentered">
                <h3 className="articleTitle">Pagination</h3>
                <p className="articleText">
                    Most API routes have an index resource which can be used to fetch larger sets of records. As the underlying collection size grows,
                    it becomes necessary to paginate the response payloads into manageable chunks. postget has a <span className="textBold">global <code className="inlineCode">500</code> record limit</span> on batch reads... but what if you want to pull more records than that? 
                </p>

                <h3 className="articleSubTitle flexTitle" id="example"><span className="material-symbols-outlined icon">lightbulb</span>Example</h3>
                <p>
                    At this time we provide the <code className="inlineCode">limit</code> and <code className="inlineCode">start'</code> query parameters to give you pagination control for large reads. If you read a collection that is larger than <code className="inlineCode">500</code> records and you do not specify a <code className="inlineCode">limit</code>, the response payload will hit the default <code className="inlineCode">limit</code> of <code className="inlineCode">500</code> records.
                </p>
                <p>
                    Once you have received a batch of records, you can extract the <code className="inlineCode">docId</code> value of the last record in the set... If you now reissue the original <code className="inlineCode">GET</code> request and set the <code className="inlineCode">start'</code> parameter to the last received <code className="inlineCode">docId</code>, the returned set will start on that document followed by the second page of records <span className="textBold">(make sure to account for the fact that you will see the connecting document twice)</span>. To fetch all records in a large set, repeat this process until the length of the returned set is smaller than the limit.
                </p>

                <p>Check out this example in python:</p>
                
                <CodeBox 
                codeText={codeExample} 
                language="python"
                codeTitle='iframe'
                lines={true}/>

            </div>
        </div>
    )
}