import './AuthForm.css'
import { useState, useEffect } from 'react'
import {useSignup} from '../../hooks/useSignup'
import { useLogin } from '../../hooks/useLogin'
import { Link } from 'react-router-dom'
import Tag from '../generic/Tag'

export default function AuthForm({title, buttonText, action='signup', confirm={}, footer={}}) {
    const [email, setEmail] = useState('')
    const [confirmEmail, setConfirmEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [error,setError] = useState(null)
    const { signup, error: signupError, isPending:signupPending } = useSignup()
    const {login, isPending: loginPending, error: loginError} = useLogin()

    const handleSubmit = (e) => {
        setError(null)
        e.preventDefault()
        switch (action) {
            case 'signup':
                handleSignup(e)
                break
            default:
                handleLogin(e)
        }
    }

    const handleSignup = (e) => {
        setError(null)
        // should require password length, special chars/nums etc...
        if (email !== confirmEmail) {
            setError('Emails do not match...')
        } else {
            signup(email, password)
        }
    
    }
    const handleLogin = (e) => {
        e.preventDefault()
        login(email,password)
    }

    return (
        <div className='authForm'>
            <form onSubmit={handleSubmit}>
                <h2 className='articleSubTitle authTitle'>{title}</h2>
                <label>
                    <span>email</span>
                    <input 
                    type="email"
                    required
                    onChange={(e)=>setEmail(e.target.value)}
                    value={email}
                    />
                </label>
                {confirm.email && 
                    <label>
                        <span>confirm email:</span>
                        <input 
                        type="email"
                        required
                        onChange={(e)=>setConfirmEmail(e.target.value)}
                        value={confirmEmail}
                        />
                    </label>
                }
                <label>
                    <span>password</span>
                    <input 
                    type="password"
                    required
                    onChange={(e)=>setPassword(e.target.value)}
                    value={password}
                    minLength={8}
                    maxLength={40}
                    />
                </label>
                {confirm.password && 
                    <label>
                        <span>confirm password:</span>
                        <input 
                        type="password"
                        required
                        onChange={(e)=>setConfirmPassword(e.target.value)}
                        value={confirmPassword}
                        minLength={8}
                        maxLength={40}
                        />
                    </label>
                }
                {(error || loginError || signupError) && <Tag color='Danger' text={error || loginError || signupError}/>}
                {!(loginPending || signupPending) && <button className="btn">{buttonText}</button>}
                {(loginPending || signupPending) && <button disabled className="btn">Loading</button>}
            </form>
            <span className='authFooter'>
                <p>{footer.text || "Don't have an account?"}</p>
                <Link to={footer.route || '/signup'}>{footer.route ? footer.route.slice(1,footer.route.length) : "signup"}</Link>
            </span>
            {action === 'login' && (
                <span className='authFooter flushTop'>
                    <p>{footer.text || "Forgot your password?"}</p>
                    <Link to={footer.route || '/reset'}>reset</Link>
                </span>
            )}
            {action === 'signup' && (
                <>
                    <span className='authFooter flushTop'>
                        <Link className='loneLink' to="/privacy">privacy policy</Link>
                    </span>
                    <span className='authFooter flushTop'>
                        <Link className='loneLink' to="/terms">terms and conditions</Link>
                    </span>
                </>
            )}
        </div>
    )
}