import './OverNav.css'
import { nanoid } from 'nanoid'
import { Link } from 'react-router-dom'
import { useState } from 'react'

export default function OverNav({navLinks, handleClose}) {
    
    const [activeSubLink, setActiveSubLink] = useState(null)

    return (
        <div className='overNav'>
            {/* <div className='navBanner'>
                <span className='material-symbols-outlined navClose inlineButton' onClick={handleClose}>
                    close
                </span>
            </div> */}
            <div className='navBody'>
                <ul>
                {navLinks.map((navLink)=>{
                    if (navLink.subRoutes){
                        return <li key={nanoid()}>
                            <div className='navLink navTitle' onClick={()=>{setActiveSubLink(prev => prev !== navLink.title && navLink.title)}}>
                                    <span className="material-symbols-outlined icon">
                                        {activeSubLink === navLink.title ? 'expand_more' : 'chevron_right'}
                                    </span>
                                {navLink.title}
                            </div>
                            {activeSubLink === navLink.title && (
                                <ul className={`subList subList${navLink.title}`}>
                                    {navLink.subRoutes.map((subLink)=>{
                                        return ( subLink.to 
                                            ? <li key={nanoid()} onClick={()=>handleClose()}>
                                                <Link to={subLink.to} className='navLink subLink'>{subLink.title}</Link>
                                            </li>
                                            : <li key={nanoid()}>
                                                <span className='navLink subLink linkHide'>{subLink.title}</span>
                                            </li>
                                        )
                                    })}
                                </ul>
                            )}
                        </li>
                    } else {
                        return (
                            <li key={nanoid()} onClick={()=>handleClose(navLink.title === 'Settings')}>
                                <Link to={navLink.to} className='navLink'>
                                    <span className="material-symbols-outlined iconHide">
                                        chevron_right
                                    </span>
                                    {navLink.title}
                                </Link>
                            </li>
                        )
                    }
                })}
                </ul>
            </div>
            <div className='navFooter'>

            </div>
        </div>
    )
}