import CodeBox from "../../../components/extra/CodeBox"
import { Link } from "react-router-dom";

export default function AuthDoc() {
    return (
        <div className="Article articleText">
            <div className="articleCentered">
                <h3 className="articleTitle">Export your documentation</h3>
                <p className="articleText">
                    As you build your apps with routes, methods, and users, API documentation will automatically generate on the <Link className="appLink textBold" to="/apps?view=routes">MyApps</Link> page. 
                    When you want to export these documents to use them on your own systems, try one of the methods outlined below.
                </p>

                <h3 className="articleSubTitle flexTitle" id="iframe"><span className="material-symbols-outlined icon">code</span>iframe</h3>
                <p>
                    Embed any postget API document securely into an existing website with a standard iframe tag. For example, 
                    given a private api key of <code className="inlineCode">MsvOiG5znweHA0EcSaLp</code>, an app docId of <code className="inlineCode">widget_app</code>, and a route docId of <code className="inlineCode">route_one</code>, the following  example would embed the target content in your webpage. 
                </p>
                
                <CodeBox codeText='<iframe src="https://postget.dev/iframe?apiKey=MsvOiG5znweHA0EcSaLp&appId=widget_app&routeId=route_one" title="My API"></iframe>' codeTitle='iframe' language=""/>

                <p>
                The full raw URL: <code className="inlineCode">{'https://postget.dev/iframe?apiKey=<key>&appId=<appId>&routeId=<routeId>'}</code>
                </p>

                <h3 className="articleSubTitle flexTitle" id="openapi"><span className="material-symbols-outlined icon">article</span>OpenApi</h3> 
                <span className="warningText smallText">{"< feature pending >"}</span>
                {/* <p>
                    Download OpenApi yaml files for your apps. This feature is currently pending.
                </p> */}
            </div>
        </div>
    )
}