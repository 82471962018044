import './MethodList.css'
import Method from './Method'
import { nanoid } from 'nanoid'

export default function MethodList({methods}) {

  return (
    <div className='methodList'>

      <div className='methodHeader'>
        <h3 className='methodTitle'><span className="material-symbols-outlined icon">lan</span>Supported methods</h3>
      </div>

      <div className='methodBody'>
        {methods.map((method)=>{
          return <span key={nanoid()}><Method method={method}/></span>
        })}
      </div>

    </div>
  )
}