import CodeBox from "../../components/extra/CodeBox";
import './NotFound.css'

const ascii = `
     ^..^      /                         
     /_/\\_____/                          
        /\\   /\\                          
        /  \\ /  \\  page not found          
`

export default function NotFound() {
    return (
      <div className="notfound navTop">
        <div className="articleCentered">
          <h3 className="articleTitle">{'<404>'} Whoops...</h3>
          <div className="notFoundCode">
            <CodeBox lines={false} headerView={false} codeText={ascii}/>
          </div>
        </div>
      </div>
    );
  }