import { nanoid } from "nanoid";
import CodeBox from "../../../components/extra/CodeBox";
import { Link } from "react-router-dom";

const errors = [
    {
        error:{'response':'Invalid resource path'},
        context:'The URL path to the resource is missing path parameters'
    },
    {
        error:{'response':'User does not have access to requested resource'},
        context:'The user account does not have sufficient permissions to the target resource'
    },
    {
        error:{'response':'Operation not supported'},
        context:'The attempted HTTP method is not supported on the target route'
    },
    {
        error:{'response':'Route is ambiguous for owner. Please include appId parameter'},
        context:'An owner account is accessing a consumer level route and did not provide the appId query parameter'
    },
    {
        error:{'response':'Object not found in path'},
        context:'An HTTP method was called that typically takes action on a record but no record id was found in URL path'
    },
    {
        error:{'response':'Operation target not found'},
        context:'An HTTP method was called on a route that either does not exist or does not support the method'
    },
    {
        error:{'response':'Insufficient permissions'},
        context:'The user does not have permission to call the requested method on the target route'
    },
    {
        error:{'response':'You have exceeded your local daily rate limit'},
        context:'The user has exceeded the limit configured on the target app'
    },
    {
        error:{'response':'Authentication failure'},
        context:'The authentication secret provided could not be verified'
    },
    {
        error:{'response':'Api key not found in Authorization header'},
        context:'The request did not contain the expected Authorization header or the header was not formatted properly'
    },
    {
        error:{'response':'Please reset your API key'},
        context:"The user's apiKey must be reset to continue service"
    },
    {
        error:{'response':'You have exceeded your global daily rate limit'},
        context:'The user has exceeded the global platform rate limit'
    },
    {
        error:{'response':{'success':false,'error':'Schema validation failure'}},
        context:'A consumer level write did not pass the schema check configured on the target route'
    },
    {
        error:{'response':{'success':false,'error':'Object not found'}},
        context:'A consumer level update/delete action was attempted on a record that does not exist'
    },
    {
        error:{'response':{'success':false,'error':'Object already exists'}},
        context:'A consumer level create action was attempted on a record that already exists'
    },
    {
        error:{'response':{'success':false,'error':'Write error'}},
        context:'A generic low level error ocurred on data write. Please check your data for errors or improper use of reserved syntax'
    },
    {
        error:{'response':'Collection has reached alpha limit of 10000 records'},
        context:'Alpha release has a 10k size limit on all route collections'
    },
    {
        error:{'response':'Account has reached alpha limit of 2 apps'},
        context:'Alpha release has a limit of 2 apps per account'
    },
    {
        error:{'response':'App has reached alpha limit of 10 routes'},
        context:'Alpha release has a 10 route limit on all apps'
    },
    {
        error:{'response':'App has reached alpha limit of 25 users'},
        context:'Alpha release has a 25 user limit on all apps'
    },
    {
        error:{'response':'Parent element not found'},
        context:'Operation targets a sub element for a parent object that does not exist'
    },
    {
        error:{'response':'Exceeded maximum number of custom match operators'},
        context:'A URL query was submitted with more than three custom match conditions'
    },
    {
        error:{'response':'Query key or value exceeded 50 char limit'},
        context:'A URL query match key or value was greater than 50 chars'
    },
    {
        error:{'response':'Query key value pairs are not valid'},
        context:'The URL query key values could not be parsed'
    },
    {
        error: {'response':"Missing required parameter: <name>"},
        context: "The targeted endpoint expected a URL query parameter which was not supplied",
    },
    {
        error: {'response':"Unable to parse parameter: <name>"},
        context: "The supplied parameter was an unexpected type or pattern",
    },
    {
        error: {'response':"You are already subscribed to this resource"},
        context: "The caller attempted to subscribe to a public app that he/she is already subscribed to",
    },
    {
        error: {'response':"Authorization failure"},
        context: "The caller is not authorized to perform the requested action in the requested context.",
    }
]

export default function ErrorDoc() {
    return (
        <div className="Article apiDoc">
            <div className="articleCentered">
                <h3 className="articleTitle">Error Archive</h3>
                <p className="articleText">
                    Please find the current archived list of error messages and their context listed below. 
                    To report a problem or bug, submit an inquiry via the <Link className="appLink textBold" to="/contact">contact page</Link>.
                </p>
                {errors.map((err, i)=>{
                    return (
                        <div key={nanoid()} className={`errorItem ${i%2 === 0 ? ' errorItemEven' : ' errorItemOdd'}`}>
                            {/* <p className="articleText listNumber">{i}</p> */}
                            <CodeBox codeText={JSON.stringify(err.error)}/>
                            <p className="articleText textLight"><em>{err.context}</em></p>
                        </div>
                    )
                })}
            </div>
      </div>
    )
}