import './PageNav.css'
import { nanoid } from 'nanoid'
import { HashLink } from 'react-router-hash-link'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

export default function PageNav({routeGroups={}}) {
    const [searchParams, setSearchParams] = useSearchParams()
    
    const { hash } = useLocation();
    const isActive = (iHash) => hash === iHash;

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -80; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    }    
    const view = searchParams.get('view')
    // console.log(routeGroups)
  return (
    <div className='pageNav'>
      <div className='pageNavContainer'>
        <div className='pageNavSection'>
          {Object.keys(routeGroups).map((group)=>{
            return (
                group === view && <React.Fragment key={nanoid()}>
                <span className='pageNavHeading'>{group.toUpperCase()}</span>
                <ul className='pageNavLinks'>
                  {routeGroups[group].map((routeObj)=>{
                    return (
                        <li key={nanoid()} className=''>
                          <HashLink 
                          smooth
                          // activeClassName="appLink"
                          scroll={el => scrollWithOffset(el)}
                          to={routeObj.path}
                          className={'pageNavLink navText'}
                          style={
                            isActive('#'+routeObj.path.split("#")[1])
                              ? {
                                  color: "var(--primary-color)"
                                }
                              : {}
                          }
                          activeStyle={{
                            color: "var(--primary-color)"
                          }}
                          >
                            {routeObj.label}
                          </HashLink>
                        </li>
                      )
                  })}
                </ul>
              </React.Fragment>
              )
          })}
        </div>
      </div>
    </div>
  )
}