import './ApiUsers.css'
import { nanoid } from 'nanoid'
import Modal from '../generic/Modal'
import { useState, useEffect } from 'react'
import { transformSchema } from '../../util/Util'
import ExampleBox from './ExampleBox'
import FeedNav from './FeedNav'

const pageSize = 20

export default function ApiUsers({userData, hits}) {
    const [focusUser, setFocusUser] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [modalHeight, setModalHeight] = useState(200)

    const [page, setPage] = useState(0)
    const [totalPages, setTotalPages] = useState(0)

    useEffect(()=>{
        setTotalPages(userData.length/pageSize)
    },[userData, pageSize])

    return (
        <div className='apiUsers'>
                {showModal && (
                    <Modal
                    height={modalHeight}
                    setShowModal={setShowModal} 
                    onConfirm={()=>{}}
                    header={focusUser.name}
                    copyText={JSON.stringify(focusUser.config,null,4)}
                    message={
                        <ExampleBox type='paramBox' examples={[
                            <>{transformSchema(focusUser.config)}</>
                        ]} />
                    }
                    type='display'
                    route='/apps?view=clients'/>
                )}

                <table className='feed'>
                    <thead className='articleText'>
                    <tr>
                        <th className='hideSmall'>ID</th>
                        <th >NAME</th>
                        <th>SINCE</th>
                        <th className='superUser'>SUPER USER</th>
                        <th className='super'>SUPER</th>
                        <th>HITS</th>
                        <th className='hideSmall'>CONFIG</th>
                    </tr>
                    </thead>
                    <tbody>
                        {userData.slice(page*pageSize,((page*pageSize)+pageSize)).map((user, index)=>(
                            <tr key={nanoid()} className={`feedItem ${index%2===0 ? 'evenRow' : 'oddRow'}`}>
                                <td className='hideSmall'>{user.id}</td>
                                <td>{user.name}</td>
                                <td><span className='date'>{user.apiClient?.keyDate?.toDate().toISOString().split('.')[0] || 'None'}</span></td>
                                <td>
                                    {user.superUser 
                                    ? <span className='material-symbols-outlined checkIcon icon'>check_circle</span> 
                                    : <span className='material-symbols-outlined textLight icon'>cancel</span>}
                                </td>
                                <td>{`R${hits[user.id]?.reads || 0}/W${hits[user.id]?.writes || 0}`}</td>
                                <td className='hideSmall'><a 
                                className='appLink textBold' 
                                to='#'
                                onMouseDown={(e)=>{
                                    e.preventDefault()
                                    setModalHeight(Math.max(e.clientY-250,100))}}
                                onMouseUp={(e)=>{
                                    e.preventDefault()
                                    setFocusUser({name:user.name,config:user.permissions})
                                    setShowModal(true)
                                }}>view</a></td>
                            </tr>
                        ))}
                    </tbody>
              </table>
                <FeedNav page={page} setPage={setPage} pageSize={pageSize} feedLength={userData.length} totalPages={totalPages}/>
        </div>
    )
}