import { useEffect, useState } from "react"
import { useAuthContext } from './useAuthContext'
import { useDocument } from "./useDocument"
import { useCollection } from "./useCollection"

export const useKey = () => {
    const {user} = useAuthContext()
    const [key, setKey] = useState(null)
    const [error, setError] = useState(null)

    const { document: userDoc } = useDocument('users',user?.uid)
    const {documents:feedDoc} = useCollection(`users/${user?.uid}/feed`, null, ['date','desc'], 250)

    useEffect(()=>{
        setKey(userDoc?.apiClient?.apiKey || '<generating new key...>')
    },[userDoc])

    const resetKey = async () => {
        setError(null)
        if (key){
            try {
                const res = await fetch(
                    // 'http://localhost:8080/api/v1/keys/reset',
                    'https://app.postget.dev/api/v1/keys/reset',
                    {
                        method: 'POST',
                        headers: {
                            Authorization:`Basic ${key}`,
                            Origin: 'https://postget.dev',
                            'Access-Control-Request-Method': 'POST',
                            'Access-Control-Request-Headers': 'Content-Type, Authorization'
                        }
                    }
                )

                const json = await res.json()
                const newKey = json.response?.apiKey

                if (newKey) {
                    setKey(newKey)
                } else {
                    setError(json.response)
                }

            } catch {
                setError('Unable to reset key')
            }
        }
    }

    const disableKey = async () => {
        setError(null)
        if (key){
            try {
                const res = await fetch(
                    'https://app.postget.dev/api/v1/keys/disable',
                    {
                        method: 'POST',
                        headers: {
                            Authorization:`Basic ${key}`,
                            Origin: 'https://postget.dev',
                            'Access-Control-Request-Method': 'POST',
                            'Access-Control-Request-Headers': 'Content-Type, Authorization'
                        }
                    }
                )

                const json = await res.json()

                if (json.response !== 'Account disabled') {
                    setError(json.response)
                    return false
                }

                return true

            } catch {
                setError('Unable to disable account')
            }
        }
        return false
    }

    const enableKey = async () => {
        setError(null)
        if (key){
            try {
                const res = await fetch(
                    'https://app.postget.dev/api/v1/keys/enable',
                    {
                        method: 'POST',
                        headers: {
                            Authorization:`Basic ${key}`,
                            Origin: 'https://postget.dev',
                            'Access-Control-Request-Method': 'POST',
                            'Access-Control-Request-Headers': 'Content-Type, Authorization'
                        }
                    }
                )

                const json = await res.json()

                if (json.response !== 'Account enabled') {
                    setError(json.response)
                    return false
                }

                return true

            } catch {
                setError('Unable to enable account')
            }
        }
        return false
    }
    return {key, feedDoc, error, resetKey, disableKey, enableKey}
}