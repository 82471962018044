import { Link } from "react-router-dom"

export default function Appstore() {
    // how to publish 
    // (flag as public, set route level permissions[record owner?, can be overwritten on user level], set contactEmail, set tags), 
    // how to consume (browse and subscribe)
    return (
        <div className='Article articleText appstoreDoc'>
          <div className="articleCentered">
            <h3 className="articleTitle">The App Store</h3>
            <p>The app store is a place to share projects, collaborate, and explore. <Link className="appLink textBold" to="/appstore">Find a project that inspires you.</Link></p>
            <h3 id="publish" className="articleSubTitle flexTitle"><span class="material-symbols-outlined icon">storefront</span>Publish</h3>
            <p>Don't have an existing UI? No problem! <span className="textBold">postget.dev</span> will host your app for free on the appstore. To publish an app to the appstore, follow these steps (note that more context for each of these parameters are located in the apps, routes, and clients schema definitions)</p>
            <ol>
                <li>Flag the app as public by setting the <code className="inlineCode">public</code> attribute to <code className="inlineCode">true</code> on your app document.</li>
                <li>Make sure to set the <code className="inlineCode">defaultPermissions</code> attribute on each route document. When users subscribe to your application, they won't have any user level permissions... If you set <code className="inlineCode">defaultPermissions</code> on the routes, new users will be able to gain access to your application on demand in real time. You can always add additional permissions on the client level after signup if needed. <Link className="appLink textBold" to="/docs?view=permissions">Review permissions</Link></li>
                <li>If you are using owner level permissions such as <code className="inlineCode">readOwned</code> or <code className="inlineCode">updateOwned</code>, make sure to set the <code className="inlineCode">flagOwner</code> attribute to <code className="inlineCode">true</code> on the respective route.</li>
                <li>Set a list of relevant <code className="inlineCode">tags</code> on your app document to make it easier to search for in the app store. Each tag should be a keyword related to your app for quick discovery.</li>
                <li>Your app should have a <code className="inlineCode">contactEmail</code> attribute configured. If a user has a question about your data or needs to request additional access, they will need some means by which to contact you.</li>
            </ol>
            <h3 id="consume" className="articleSubTitle flexTitle"> <span class="material-symbols-outlined icon">shopping_bag</span>Consume</h3>
            <p>Chances are someone has or will build a cool application you may want to use. Check out the <Link className="appLink textBold" to="/appstore">app store</Link> to browse or search for projects that interest you. See something you like? If you're logged in, you can generate a new key to any published app. <span className="textBold">postget.dev</span> is a great place to explore and exchange ideas!</p>
          </div>
        </div>
    )
  }