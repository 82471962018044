import './SideNav.css'
import { nanoid } from 'nanoid'
import { NavLink, useSearchParams } from 'react-router-dom'
import React from 'react'

export default function SideNav({routeGroups={}}) {
  const [searchParams, setSearchParams] = useSearchParams()
  const view = searchParams.get('view')
  const focus = searchParams.get('focus')

  const isActivePage = (label) => {
    return view?.toLowerCase() === label.toLowerCase() || focus?.toLowerCase() === label.toLowerCase()
  }

  // if active page has sub routes... redirect to first focus...

  return (
    <div className='sideNav'>
      <div className='sideNavContainer'>
        <div className='sideNavSection'>
          {Object.keys(routeGroups).map((group)=>{
            return (
              <React.Fragment key={nanoid()}>
                <span className='sideNavHeading'>{group.toUpperCase()}</span>
                <ul className='sideNavLinks'>
                  {routeGroups[group].map((routeObj)=>{
                    return (
                        <li key={nanoid()} className=''>
                          <NavLink 
                          to={routeObj.path}
                          className={'sideNavLink navText'+(isActivePage(routeObj.value) ? ' activeRoute' : '')}
                          >
                            {routeObj.label}
                          </NavLink>
                          {routeObj.subPaths?.length > 0 && isActivePage(routeObj.value) && (
                            <ul className='subSideNavLinks'>
                              {routeObj.subPaths?.map(((subRoute, index)=>{
                                return <li key={nanoid()}>
                                  <NavLink 
                                  to={subRoute.path}
                                  className={'subSideNavLink navText'+ (isActivePage(subRoute.value) ? ' activeRoute' : '')}
                                  >
                                    {subRoute.label}
                                  </NavLink>
                                </li>
                            }))}
                            </ul>
                          ) }
                        </li>
                      )
                  })}
                </ul>
              </React.Fragment>
              )
          })}
        </div>
      </div>
    </div>
  )
}